import React, { Component } from "react";

import { Popup, PopupContainer, PopupContent, PopupPreview } from "./Popup";
import { ImageOptionList } from "./ImageOptionList";
import { MixedValue } from "./MixedValue";
import styled from "styled-components";

const StyledPopupPreview = styled.div`
    border: ${props => props.border ? "solid 1px #ccc" : "none"};
    background: white;
    padding:  ${props => props.border ? "0px" : "0px"};
`;

export class ImagePopup extends Component {
    render() {
        const { children, value, onChange, showLabel, showArrow = true, border = true, previewSize = 70, cols, size, gap = 20, useDropdownStyle } = this.props;

        let PreviewComponent = <MixedValue />;
        if (value !== "mixed") {
            let selected = React.Children.toArray(children).find(child => child.props.value == value);
            if (!selected) {
                selected = React.Children.toArray(children)[0];
            }

            if (selected) {
                PreviewComponent = React.cloneElement(selected, {
                    label: showLabel ? selected.props.label : undefined,
                    size: previewSize,
                });
            }
        }

        return (
            <Popup showArrow={showArrow}>
                <PopupPreview>
                    <StyledPopupPreview border={border}>
                        {PreviewComponent}
                    </StyledPopupPreview>
                </PopupPreview>
                <PopupContent>
                    {closeMenu => (
                        <PopupContainer>
                            <ImageOptionList
                                value={value}
                                onChange={value => {
                                    onChange(value);
                                    closeMenu();
                                }}
                                gap={gap}
                                size={size}
                                cols={cols}
                            >
                                {children}
                            </ImageOptionList>
                        </PopupContainer>
                    )}
                </PopupContent>
            </Popup>
        );
    }
}


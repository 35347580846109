import React, { Component } from "react";
import { Icon } from "../../../../Components/Icon";
import { ImageOption, ImageOptionList } from "../../../../Components/ImageOptionList";
import { Slider } from "../../../../Components/Slider";
import { StaticImage } from "../../../../Components/StaticImage";
import { WithLabel } from "../../../../Components/WithLabel";
import { PropertyPanelContainer, PropertySection, PropertySectionHeader } from "../../../../EditorComponents/PropertyPanel";
import { ShowErrorDialog } from "../../../../react/components/Dialogs/BaseDialog";
import { FlexBox } from "../../../../react/components/LayoutGrid";
import { ColorPicker } from "../EditorComponents/ColorPickers/ColorPicker";
import { ImageFramePopupMenu } from "../EditorComponents/ImageFrameMenu";
import { TextListPropertyPanel } from "./TextListUI";

export class ProductScreenshotUI extends Component {
    render() {
        const { element } = this.props;

        const mediaColumn = element.itemElements.find(element => element.model.isMedia);

        let layout = "none";
        if (element.itemElements.length === 2) {
            if (mediaColumn.itemIndex === 0) {
                layout = "two-left";
            } else {
                layout = "two-right";
            }
        } else if (element.itemElements.length === 3) {
            if (mediaColumn.itemIndex === 0) {
                layout = "three-left";
            } else if (mediaColumn.itemIndex === 1) {
                layout = "three-center";
            } else {
                layout = "three-right";
            }
        }

        let listBlocks = element.allBlockModels.filter(block => block.listStyle && !block.indent);
        let hasListBlocks = listBlocks.length > 0;

        return (
            <PropertyPanelContainer>
                <PropertySection>
                    <PropertySectionHeader label="Device Frame" />
                    <FlexBox fillWidth>
                        <ImageFramePopupMenu
                            size={70} showPreview
                            frameType={mediaColumn.model.frameType}
                            allowedCategories={["device", "decorative"]}
                            onChange={frameType => mediaColumn.updateModel({ frameType })}
                            showRemoveFrameButton
                        />
                    </FlexBox>
                    <WithLabel label="Frame Background Color" left>
                        <ColorPicker value={mediaColumn.media.model.frameColor ?? "theme"} canvas={element.canvas}
                            onChange={color => mediaColumn.media.updateModel({ frameColor: color })}
                            showBackgroundColors allowColorOnColor
                        />
                    </WithLabel>
                    {layout != "none" && (
                        <WithLabel label="Size" flex>
                            <Slider value={mediaColumn.model.size ?? 300}
                                showInput
                                min={100} max={1000} step={1}
                                onChange={value => mediaColumn.refreshModel({ size: value })}
                                onCommit={() => mediaColumn.saveModel()}
                            />
                        </WithLabel>
                    )}
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Layout" above>
                        <ImageOptionList value={layout}
                            onChange={value => {
                                try {
                                    let textCols, mediaColumnIndex;
                                    switch (value) {
                                        case "none":
                                            textCols = 0;
                                            break;
                                        case "two-left":
                                            textCols = 1;
                                            break;
                                        case "two-right":
                                            textCols = 1;
                                            mediaColumnIndex = 1;
                                            break;
                                        case "three-left":
                                            textCols = 2;
                                            mediaColumnIndex = 0;
                                            break;
                                        case "three-center":
                                            textCols = 2;
                                            mediaColumnIndex = 1;
                                            break;
                                        case "three-right":
                                            textCols = 2;
                                            mediaColumnIndex = 2;
                                            break;
                                    }

                                    element.distributeBullets(textCols, null, mediaColumnIndex);
                                } catch (err) {
                                    ShowErrorDialog({ title: "Sorry, we couldn't make this change", message: err });
                                    element.canvas.revertCanvasModel();
                                    return;
                                }
                                element.canvas.updateCanvasModel();
                            }}
                            cols={3}
                        >
                            <ImageOption value="none"><Icon><StaticImage src="/images/ui/product-layout/product-layout-one.svg" /></Icon></ImageOption>
                            <ImageOption value="two-left"><Icon><StaticImage src="/images/ui/product-layout/product-layout-two-left.svg" /></Icon></ImageOption>
                            <ImageOption value="two-right"><Icon><StaticImage src="/images/ui/product-layout/product-layout-two-right.svg" /></Icon></ImageOption>
                            <ImageOption value="three-left"><Icon><StaticImage src="/images/ui/product-layout/product-layout-three-left.svg" /></Icon></ImageOption>
                            <ImageOption value="three-center"><Icon><StaticImage src="/images/ui/product-layout/product-layout-three-center.svg" /></Icon></ImageOption>
                            <ImageOption value="three-right"><Icon><StaticImage src="/images/ui/product-layout/product-layout-three-right.svg" /></Icon></ImageOption>
                        </ImageOptionList>
                    </WithLabel>
                </PropertySection>

                {element.textColumnsCount > 0 && hasListBlocks && <TextListPropertyPanel element={element} showColumnControls={false} />}
            </PropertyPanelContainer>
        );
    }
}


import * as geom from "js/core/utilities/geom";

import anime from "animejs";
import { BlockStructureType } from "common/constants";
import { delay } from "js/core/utilities/promiseHelper";
import { ActionTextPropertyPanel } from "../../../Editor/ElementPropertyPanels/ActionTextUI";
import { BaseElement } from "../../base/BaseElement";
import { ContentElement } from "../../base/ContentElement";
import { TextElement } from "../../base/Text/TextElement";
import { playActionTextAnimation } from "./animations/ActionTextAnimations";

class ActionText extends BaseElement {
    static get schema() {
        return {
            animationStyle: 1,
            playOutroAnimation: true
        };
    }

    get name() {
        return "Action Text";
    }

    getElementPropertyPanel() {
        return ActionTextPropertyPanel;
    }

    get showImage() {
        return this.model.image && this.model.image.content_value;
    }

    _build() {
        if (this.showImage) {
            this.image = this.addElement("image", () => ContentElement, {
                model: this.model.image
            });
        }

        this.text = this.addElement("text", () => ActionTextElement, {
            autoWidth: true,
            autoHeight: true,
            allowNewLines: true,
            blockStructure: BlockStructureType.SINGLE_BLOCK
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        if (this.showImage) {
            let canvasMargins = this.getCanvasMargins();
            let imageProps = this.image.calcProps(size.inflate(canvasMargins));
            imageProps.bounds = new geom.Rect(-canvasMargins.left, -canvasMargins.top, imageProps.size);
            imageProps.layer = -1;
        }

        let textProps = this.text.calcProps(size);
        textProps.bounds = new geom.Rect(0, 0, textProps.size).centerInRect(new geom.Rect(0, 0, size));

        return { size };
    }

    get animateChildren() {
        return false; // turn off the built-in text animations
    }

    async previewAnimation() {
        this.canvas.selectionLayerController?.setSelectedElements([]);

        if (this.animation) {
            this.animation.timeline.pause();
            this.refreshRender(true, { regenerateDOM: true });
            this.canvas.refreshCanvas();
            this.animation = null;
        } else {
            return new Promise(resolve => {
                this.playAnimationIn(async () => {
                    // await delay(1000);
                    // this.actionText.playAnimationOut(async () => {
                    //     await delay(1000);
                    this.refreshRender(true, { regenerateDOM: true });
                    this.canvas.refreshCanvas();

                    this.animation = null;
                    resolve();
                    // });
                });
            });
        }
    }

    stopPreviewAnimation() {

    }

    async _beforeShowElement() {
        this.prepareForAnimation();
        await delay(300);
        this.playAnimationIn();
        this.canvas.registerOutroAnimation(this);
    }

    async playAnimationOut() {
        if (this.model.playOutroAnimation) {
            return new Promise(resolve => {
                let timeline = anime.timeline({
                    easing: "easeInQuad"
                });

                timeline.add({
                    targets: this.text.DOMNode,
                    scale: 3,
                    opacity: 0,
                    duration: 600
                });

                timeline.finished.then(() => {
                    resolve();
                });
                timeline.play();
            });
        }
    }

    prepareForAnimation() {
    }

    playAnimationIn(onComplete) {
        this.animation = playActionTextAnimation(this, this.model.animationStyle, onComplete);
    }
}

class ActionTextElement extends TextElement {
}

export const elements = {
    ActionText
};


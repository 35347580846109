import { PropertyPanelContainer, PropertySection, PropertySectionHeader } from "js/EditorComponents/PropertyPanel";
import React, { Component } from "react";
import { CalloutType, ForeColorType, PaletteColorType, ShapeType } from "../../../../../../common/constants";
import { Dropdown } from "../../../../../Components/Dropdown";
import { ImageOption } from "../../../../../Components/ImageOptionList";
import { ImagePopup } from "../../../../../Components/ImagePopup";
import { MenuItem } from "../../../../../Components/Menu";
import { NumericStepper } from "../../../../../Components/NumericStepper";
import { Slider } from "../../../../../Components/Slider";
import { StaticImage } from "../../../../../Components/StaticImage";
import { ToggleSwitch } from "../../../../../Components/ToggleSwitch";
import { WithLabel } from "../../../../../Components/WithLabel";
import { CALLOUTS } from "../../../../../editor/ElementEditor/CalloutsPane";
import { PropertyPanelHeader } from "../../../../../editor/ElementEditor/PropertyPanelHeader";
import { PopupMenu } from "../../../../../react/components/PopupMenu";
import { _ } from "../../../../../vendor";
import { ItemColorPicker } from "../../../Editor/EditorComponents/ColorPickers/ItemColorPicker";
import { ImageFrameMenu } from "../../../Editor/EditorComponents/ImageFrameMenu";
import { ColorPicker } from "../../../Editor/EditorComponents/ColorPickers/ColorPicker";
import { DeviceFrames } from "../../DeviceFrames";

export class CalloutPropertyPanel extends Component {
    get containerElement() {
        const { element } = this.props;
        return element.isInstanceOf("AuthoringElementContainer") ? element : element.findClosestOfType("AuthoringElementContainer");
    }

    getContainerElement(element) {
        return element.findClosestOfType("AuthoringElementContainer");
    }

    render() {
        let { selectedElements } = this.props;

        selectedElements = selectedElements.map(el => el.findClosestOfType("AuthoringElementContainer"));

        let selectedElement = selectedElements[0];

        const authoringCanvas = selectedElement.findClosestOfType("AuthoringCanvas");

        const containerElement = this.containerElement;

        let name = containerElement.name;
        if (selectedElements.length > 1) {
            name = `${selectedElements.length} Elements`;
        }

        const calloutType = authoringCanvas.getChildValues("calloutType", selectedElements);
        const isShape = calloutType.equalsAnyOf(CalloutType.BOX, CalloutType.CIRCLE, CalloutType.CAPSULE, CalloutType.DIAMOND);

        const widthKey = selectedElements[0].findClosestOfType("AuthoringElementContainer").widthKey;

        const allowedCalloutTypes = authoringCanvas.getAllowedCalloutTypes().filter(type => type != CalloutType.LINE_ARROW).map(calloutType => CALLOUTS.find(c => c.value == calloutType));

        const hasContent = calloutType === CalloutType.CONTENT_AND_TEXT || calloutType === CalloutType.CONTENT;

        const frameType = authoringCanvas.getChildValues("frameType", selectedElements);
        let allowDecorationStyles = calloutType != CalloutType.BULLET_TEXT || frameType?.equalsAnyOf(ShapeType.RECT, ShapeType.ROUNDED_RECT, ShapeType.CIRCLE, ShapeType.OCTAGON);

        let fontColor = _.uniq(selectedElements.filter(Boolean).map(element => element.childElement.text?.getFontColor()));
        fontColor = fontColor.length == 1 ? fontColor[0] : "mixed";

        let fontSize = _.uniq(selectedElements.filter(Boolean).map(element => element.childElement.text?.getFontSize()));
        fontSize = fontSize.length == 1 ? fontSize[0] : "mixed";

        let textInset = _.uniq(selectedElements.filter(Boolean).map(element => element.childElement.model.textInset));
        textInset = textInset.length == 1 ? textInset[0] : "mixed";

        let fitToText = _.uniq(selectedElements.filter(Boolean).map(element => element.childElement.model.fitToText));
        fitToText = fitToText.length == 1 ? fitToText[0] : "mixed";

        let textPosition = _.uniq(selectedElements.filter(Boolean).map(element => element.childElement.model.textDirection));
        textPosition = textPosition.length == 1 ? textPosition[0] : "mixed";

        let color = _.uniq(selectedElements.filter(Boolean).map(element => element.model.color ?? element.findClosestOfType("CollectionElement")?.collectionColor));
        color = color.length == 1 ? color[0] ?? "theme" : "mixed";

        let backgroundColor = selectedElement.getBackgroundColor();
        let hasDeviceFrame = false;
        if (selectedElements.some(element => element.childElement.mediaElement != null)) {
            let hasShapeFrame = selectedElements.some(el => el.childElement.mediaElement?.hasShapeFrame);
            hasDeviceFrame = selectedElements.some(el => el.childElement.mediaElement?.hasDeviceFrame);
            let mediaBackgroundColors = selectedElements.map(el => el.childElement.mediaElement?.getBackgroundColor());
            if (hasDeviceFrame || mediaBackgroundColors.some(color => selectedElement.palette.doesBackgroundColorAllowColorOnTop(color))) {
                // this handles allowing color for an icon that's on a filled frame that is white because the canvas is color
                backgroundColor = selectedElement.palette.getColor("white");
            }
            if (allowDecorationStyles) {
                allowDecorationStyles = hasShapeFrame;
            }
        }

        let defaultColor;

        // if this callout is a child node of a FlowChart, use the collection color for the default color
        // other callouts don't show the default (auto) color
        if (containerElement.findClosestOfType("NodeDiagram")) {
            defaultColor = containerElement.collectionColor;
        }

        return (
            <PropertyPanelContainer>
                <PropertyPanelHeader>{name}</PropertyPanelHeader>
                <PropertySection>
                    <PropertySectionHeader label="Type">
                        <ImagePopup value={calloutType} border={false}
                            cols={4} size={60} previewSize={50}
                            onChange={value => {
                                let model = {
                                    calloutType: value,
                                    fitToText: value == CalloutType.BOX,
                                    textColor: null
                                };

                                if (value == CalloutType.CONTENT) {
                                    model.width = 80;
                                    model.height = 80;
                                }

                                authoringCanvas.updateChildModels(model, { selectedElements });
                            }}>
                            {allowedCalloutTypes.map(callout => (
                                <ImageOption key={callout.value} value={callout.value} label={callout.label}>
                                    <StaticImage src={callout.image} />
                                </ImageOption>
                            ))}
                        </ImagePopup>
                    </PropertySectionHeader>
                </PropertySection>
                <PropertySection>
                    {selectedElements.some(element => element.childElement.canChangeColor) && (
                        <WithLabel label="Color">
                            <ColorPicker canvas={selectedElement.canvas}
                                value={color}
                                showPrimary={!hasDeviceFrame} showSecondary={!hasDeviceFrame} showBackgroundColors={hasDeviceFrame}
                                allowColorOnColor={selectedElement.isOnAuthoringCanvas || hasDeviceFrame}
                                showDecorationStyles={allowDecorationStyles}
                                backgroundColor={backgroundColor}
                                defaultColor={defaultColor}
                                onChange={color => {
                                    for (let element of selectedElements) {
                                        element.model.color = color;
                                    }
                                    authoringCanvas.saveModel();
                                }}
                                onChangeDecorationStyle={decorationStyle => {
                                    for (let element of selectedElements) {
                                        element.model.decorationStyle = decorationStyle;
                                    }
                                    authoringCanvas.saveModel();
                                }}
                            />
                        </WithLabel>
                    )}
                    {calloutType === CalloutType.FLEX_CIRCLE && (
                        <WithLabel label="Bubble Size">
                            <Slider value={authoringCanvas.getChildValues("markerSize", selectedElements)}
                                onChange={value => authoringCanvas.updateChildModels({ markerSize: value }, { selectedElements })}
                                min={10} max={400} step={1} showInput
                            />
                        </WithLabel>
                    )}
                </PropertySection>

                {hasContent &&
                    <PropertySection>
                        <WithLabel label="Media Scale">
                            <Slider value={authoringCanvas.getChildValues(widthKey, selectedElements)}
                                onChange={value => authoringCanvas.updateChildModels({ [widthKey]: value }, { selectedElements })}
                                min={50} max={400} step={1}
                            />
                        </WithLabel>
                        <WithLabel label="Media Frame">
                            <PopupMenu icon="filter_frames">
                                <ImageFrameMenu
                                    selectedFrame={frameType}
                                    onSelect={frameType => {
                                        let frame = DeviceFrames.findById(frameType);
                                        let frameColor;
                                        if (frame.category == "Shape") {
                                            if (color == PaletteColorType.BACKGROUND_ACCENT || color == PaletteColorType.BACKGROUND_LIGHT || color == PaletteColorType.BACKGROUND_DARK) {
                                                frameColor = ForeColorType.PRIMARY;
                                            }
                                        } else {
                                            if (color == ForeColorType.PRIMARY || color == ForeColorType.SECONDARY) {
                                                frameColor = PaletteColorType.BACKGROUND_DARK;
                                            }
                                        }
                                        authoringCanvas.updateChildModels({ frameType, color: frameColor }, { selectedElements });
                                    }}
                                />
                            </PopupMenu>
                        </WithLabel>
                    </PropertySection>
                }

                <PropertySection>
                    {selectedElements.every(element => element.childElement.canChangeTextDirection) && (
                        <WithLabel label="Text Position">
                            <Dropdown value={textPosition ?? "auto"}
                                onChange={value => authoringCanvas.updateChildModels({ textDirection: value }, { refreshStyles: true, selectedElements })}
                            >
                                <MenuItem value="auto">Auto</MenuItem>
                                <MenuItem value="right">Right</MenuItem>
                                <MenuItem value="left">Left</MenuItem>
                                <MenuItem value="top">Above</MenuItem>
                                <MenuItem value="bottom">Below</MenuItem>
                            </Dropdown>
                        </WithLabel>
                    )}
                    {isShape && (
                        <>
                            {!selectedElements[0].childElement.preserveAspectRatio && (
                                <WithLabel label="Fit Height to Text" toolTip={
                                    <>
                                        <p>When this setting is on, the shape will resize automatically it's height to fit to the text.</p>
                                        <p>When this setting is off, the text in a shape will automatically shrink to fit within the shape.</p>
                                    </>
                                }>
                                    <ToggleSwitch value={fitToText}
                                        onChange={value => {
                                            for (let element of selectedElements) {
                                                element.childElement.model.fitToText = value;
                                            }
                                            authoringCanvas.saveModel();
                                        }} />
                                </WithLabel>
                            )}
                            <WithLabel label="Text Inset" toolTip="Sets the inset between the edges of the shape and any text inside the shape.">
                                <NumericStepper value={textInset}
                                    onChange={value => {
                                        for (let element of selectedElements) {
                                            element.childElement.text.model.textInset = value;
                                        }
                                        authoringCanvas.saveModel();
                                    }} />
                            </WithLabel>
                        </>
                    )}
                </PropertySection>
            </PropertyPanelContainer>
        );
    }
}


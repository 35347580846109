import React, { Component } from "react";
import styled from "styled-components";

import { ToggleButton as MaterialToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { themeColors } from "../react/sharedStyles";
import { FlexBox } from "../react/components/LayoutGrid";

const StyledToggleButton = styled(MaterialToggleButton)`
    &&& {
        border-radius: 0px;
        padding: 6px 12px;
        background: #ddd;
        border: solid 1px white;
        font-size: 12px;
        font-weight: 600;
        color: #333;
        font-family: inherit;
        letter-spacing: normal;

        &.Mui-selected {
            background: ${themeColors.ui_blue};
            color: white;

            .bai-icon {
                color: white !important;
            }
        }

        .MuiToggleButton-label {
            .bai-icon {
                font-size: 16px;
                color: #555;
                margin-right: 5px;
            }
        }
    }
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
    &&& {
        ${props => props.disabled ? `
            pointer-events: none;
            opacity: 0.5;
        ` : ""}
    }
`;

export class ToggleButtons extends Component {
    render() {
        const {
            onChange = () => { },
            children,
            ...props
        } = this.props;

        const currentValue = props.value;

        return (
            <StyledToggleButtonGroup
                exclusive
                {...props}
                onChange={(event, value) => {
                    if (value === null) {
                        return currentValue;
                    }

                    onChange(value);
                }}
            >
                {children}
            </StyledToggleButtonGroup>
        );
    }
}

export class ToggleButton extends Component {
    render() {
        const { children, ...props } = this.props;

        return (
            <StyledToggleButton {...props}>
                {children}
            </StyledToggleButton>
        );
    }
}

const StyledVerticalToggleButton = styled(MaterialToggleButton)`
    &&& {
        padding: 6px 10px 2px;
        background: none;
        border: solid 1px rgba(0,0,0,0);
        border-radius: 0px;
        color: #333;
        
        font-family: inherit;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: normal;
        
        min-width: 60px;
        margin-right: 5px;
        .MuiToggleButton-label {
            text-wrap: nowrap;
            .bai-icon {
                font-size: 22px;
                color: #888;
                margin-right: 0px;
            }
        }
        
        &.Mui-selected {
            background: ${themeColors.selection};
            border: solid 1px ${themeColors.ui_blue};
            color: #333;
            .bai-icon {
                color: ${themeColors.ui_blue} !important;
            }
        }
    }
`;

export class VerticalToggleButton extends Component {
    render() {
        const { children, ...props } = this.props;

        return (
            <StyledVerticalToggleButton {...props}>
                <FlexBox vertical center middle gap={2}>
                    {children}
                </FlexBox>
            </StyledVerticalToggleButton>
        );
    }
}

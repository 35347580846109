import { GridLayoutContainer, LayoutEngineType } from "./GridLayoutContainer";
import { _ } from "js/vendor";
import { BottomTrayPropertyPanel, BottomTraySelection } from "../../../Editor/ElementPropertyPanels/GridContainer/BottomTrayUI";
import { ElementTextBlockPositionType, TextStyleType } from "../../../../../../common/constants";
import * as geom from "../../../../../core/utilities/geom";

export class BottomTray extends GridLayoutContainer {
    static get schema() {
        return {
            layoutEngineType: LayoutEngineType.BOTTOM_TRAY,
        };
    }

    get name() {
        return "Bottom Tray";
    }

    getElementSelection() {
        return BottomTraySelection;
    }

    getElementPropertyPanel() {
        return BottomTrayPropertyPanel;
    }

    get hideControlBarWhenTextSelected() {
        return true;
    }

    get defaultTextStyle() {
        return TextStyleType.TITLE;
    }

    getAvailableTextIdeas() {
        return ["title"];
    }

    get _canSelect() {
        return true;
    }

    get fullBleed() {
        return true;
    }

    get trayHeight() {
        return this.model.trayHeight ?? 200;
    }

    get autoResize() {
        return this.model.trayHeight == null;
        // &&
        //     this.itemElements.length == 1 &&
        //     this.itemElements[0].model.componentType == InfographicElementTypes.TEXT_AND_IMAGE &&
        //     this.itemElements[0].childElement.showText &&
        //     (this.itemElements[0].childElement.model.backdropStyle == "none" || this.itemElements[0].childElement.model.backdropStyle == null);
    }

    get minHeight() {
        return this.minTrayHeight;
    }

    get minTrayHeight() {
        return 70;
    }

    get maxTrayHeight() {
        return 150;
    }

    get minItemCount() {
        return 0;
    }

    _calcProps(props, options) {
        let { size } = props;

        let hasText = this.itemElements.some(item => item.childElement.showText);

        if (this.model.trayHeight) {
            size.height = this.model.trayHeight;
        } else if (!hasText) {
            // default height for no text
            size.height = 70;
        }

        let calculatedProps = super._calcProps(props, {
            autoHeight: true
        });

        // if no trayHeight is set and there are text elements, recalc using the text height
        if (!this.model.trayHeight && hasText) {
            // calculate the maximum text height of any text element in the tray
            let textHeight = _.max(this.itemElements.filter(item => item.childElement.showText).map(item => item.childElement.textFrame?.text?.calculatedProps.textBounds?.height + (item.childElement.model.backdropPadding ?? 30) * 2));
            calculatedProps = super._calcProps({ ...props, size: new geom.Size(size.width, textHeight) }, options);
        }

        return calculatedProps;
    }

    deleteItem(itemId) {
        super.deleteItem(itemId);
        if (this.itemCount == 0) {
            this.canvas.model.layout.elementTextBlockPosition = ElementTextBlockPositionType.NONE;
            this.canvas.updateCanvasModel(true);
        }
    }

    _exportToSharedModel() {
        return { bottomTray: this.model };
    }

    _importFromSharedModel(model) {
        return model.bottomTray;
    }

    _migrate_10_02() {
        super._migrate_10_02();

        for (let item of this.itemCollection) {
            item.cellColor = this.palette.getColor(item.cellColor, this.getBackgroundColor(), { allowColorOnColor: true }).name;
        }
    }
}


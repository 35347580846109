import { AuthoringElementType, AuthoringShapeType, BlockStructureType, CalloutType, TextStyleType } from "../../../../../../common/constants";
import * as geom from "../../../../../core/utilities/geom";
import { AnchorType } from "../../../../../core/utilities/geom";
import { _ } from "../../../../../vendor";

import { AuthoringShapeElement } from "../authoring/AuthoringShape";

export class ShapeCallout extends AuthoringShapeElement {
    static get schema() {
        return {
            ...super.schema,
            titleTextStyle: "title"
        };
    }

    get limitSize() {
        return true;
    }

    get showRolloverBlock() {
        return true;
    }

    get isTextAndShape() {
        return true;
    }

    get canAddBlocks() {
        return false;
    }

    get minWidth() {
        return 50;
    }

    get minHeight() {
        return 20;
    }

    get syncFontSizeWithSiblings() {
        return true;
    }

    get referencePoint() {
        return this.bounds.center;
    }

    get referencePointAnchor() {
        return geom.AnchorType.CENTER;
    }

    get preventSelfPointingConnector() {
        return true;
    }

    get isCallout() {
        return true;
    }

    get canRollOver() {
        return false;
    }

    get blockStructure() {
        return BlockStructureType.HEADER;
    }

    get canChangeColor() {
        return true;
    }

    get canChangeStroke() {
        return false;
    }

    get allowEmphasized() {
        return true;
    }

    get allowedBlockTypes() {
        return [
            TextStyleType.HEADING,
            TextStyleType.TITLE,
            TextStyleType.BODY,
            TextStyleType.LABEL
        ];
    }

    get authoringElementType() {
        return AuthoringElementType.SHAPE;
    }

    get minTextWidth() {
        return 100;
    }

    get maxTextWidth() {
        return 1280;
    }

    get textWidth() {
        return this.model.textWidth ?? 200;
    }

    setUserWidth(width) {
        this.model.textWidth = Math.clamp(width, this.minTextWidth, this.maxTextWidth);
    }

    get connectionShape() {
        return {
            bounds: this.bounds,
            type: this.model.shape
        };
    }

    getDefaultConnectorColor() {
        if (!this._colorSet) {
            // a connectorItem can potentiall call this function before the colorSet is initialized
            this._applyColors();
        }
        if (this.model.strokeWidth > 0 && this.colorSet.strokeColor) {
            return this.colorSet.strokeColor.setAlpha(1).toRgbString();
        } else {
            return this.colorSet.fillColor.setAlpha(1).toRgbString(); // note remove alpha in case we are muted
        }
    }

    // get canSelectText() {
    //     return true;
    // }
    //
    // get doubleClickToSelectText() {
    //     return false;
    // }

    get canReshape() {
        return false;
    }

    get hasText() {
        return this.options.hasText ?? true;
    }

    get scaleTextToFit() {
        return true;
    }

    get textOptions() {
        return {
            ...super.textOptions,
            canDeleteLastBlock: false
        };
    }

    get availableAnchorPoints() {
        return [AnchorType.FREE, AnchorType.LEFT, AnchorType.RIGHT, AnchorType.TOP, AnchorType.BOTTOM, AnchorType.BOTTOM_RIGHT];
    }

    get rolloverPadding() {
        return 20; // extra padding to bounds used when detecting if mouse is rolled over this element
    }

    get isShapeCallout() {
        return [CalloutType.DIAMOND, CalloutType.CAPSULE, CalloutType.CIRCLE, CalloutType.BOX].includes(this.model.calloutType);
    }

    get decorationStyle() {
        return this.model.decorationStyle || this.canvas.getTheme().get("styleElementStyle");
    }

    get showTextIsClippedWarning() {
        return true;
    }

    get showFontSize() {
        return false;
    }

    get connectorsFromNode() {
        const container = this.findClosestOfType("AuthoringCanvas");
        if (container.connectors) {
            return container.connectors.getConnectorsForItem(this.parentElement.id, "source");
        } else {
            return [];
        }
    }

    get connectorsToNode() {
        const container = this.findClosestOfType("AuthoringCanvas");
        if (container.connectors) {
            return container.connectors.getConnectorsForItem(this.parentElement.id, "target");
        } else {
            return [];
        }
    }

    get childNodes() {
        const container = this.findClosestOfType("AuthoringCanvas");
        return _.map(this.connectorsFromNode, c => container.getChild(c.model.target));
    }

    get parentNodes() {
        const container = this.findClosestOfType("AuthoringCanvas");
        return _.map(this.connectorsToNode, c => container.getChild(c.model.source));
    }

    _loadStyles(styles) {
        this.model.adj1 = styles.shape.cornerRadius;
    }

    _build() {
        super._build();

        switch (this.model.calloutType) {
            case CalloutType.CAPSULE: {
                this.model.shape = AuthoringShapeType.CAPSULE;
                break;
            }
            case CalloutType.DIAMOND: {
                this.model.shape = AuthoringShapeType.DIAMOND;
                break;
            }
            case CalloutType.CIRCLE: {
                this.model.shape = AuthoringShapeType.CIRCLE;
                this.model.height = this.model.width;
                this.model.fitToText = false;
                break;
            }
            case CalloutType.BOX:
            default: {
                this.model.shape = AuthoringShapeType.RECT;
                break;
            }
        }
    }

    getShapeStyles() {
        const styles = {};
        styles.fill = this.colorSet.fillColor ?? "none";
        styles.stroke = this.colorSet.strokeColor ?? "none";
        styles.strokeWidth = this.canvas.styleSheet.$shapeStrokeWidth;

        return styles;
    }

    _applyColors() {
        let decorationColor = this.palette.getColor(this.model.color ?? this.findClosestOfType("CollectionElement").collectionColor, this.parentElement.getBackgroundColor(), { itemIndex: this.itemIndex, forceWhiteForPrimaryOnColor: true });
        let { decorationFillColor, decorationStrokeColor, backgroundColor } = this.palette.getDecorationColors(decorationColor, this.decorationStyle);

        this.colorSet.fillColor = decorationFillColor ?? this.canvas.getBackgroundColor();
        this.colorSet.strokeColor = decorationStrokeColor;
        this.colorSet.backgroundColor = backgroundColor;
    }
}

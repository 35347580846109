import React, { Component } from "react";

import { _ } from "js/vendor";
import { Popup, PopupContent } from "js/Components/Popup";
import { Button } from "js/Components/Button";
import { Icon } from "js/Components/Icon";
import { Box } from "js/Components/Box";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import LinkDataDialog from "js/react/views/LinkData/LinkDataDialog";
import DataLockedDialog from "js/react/components/Dialogs/DataLockedDialog";
import { LinkedDataMenu } from "../../EditorComponents/LinkedDataMenu";

export class EditDataPanel extends Component {
    state = { showingDataLockedDialog: false };

    componentDidMount() {
        window.addEventListener("dblclick", this.handleShowDataLocked);
    }

    componentWillUnmount() {
        window.removeEventListener("dblclick", this.handleShowDataLocked);
    }

    handleShowDataLocked = () => {
        const { element } = this.props;
        const { showingDataLockedDialog } = this.state;

        if (element.parentElement.hasDataSourceLink() && !showingDataLockedDialog) {
            this.setState({ showingDataLockedDialog: true });
            ShowDialog(DataLockedDialog, {
                element: element.parentElement,
                onClose: () => this.setState({ showingDataLockedDialog: false })
            });
        }
    }

    // Helper Function to Generate Column Labels
    getColumnLabel = index => {
        let label = "";
        while (index >= 0) {
            label = String.fromCharCode((index % 26) + 65) + label;
            index = Math.floor(index / 26) - 1;
        }
        return label;
    }

    handleLinkData = processedData => {
        const { element } = this.props;
        const containerItem = element.parentElement;

        processedData.initialImport = true;
        containerItem._useUpdatedDataSource(processedData);
        element.canvas.updateCanvasModel(false);
    };

    handleUnlinkData = () => {
        const { element } = this.props;
        const containerItem = element.parentElement;

        containerItem.removeDataSource();
        containerItem.model.dataSourceLink = null;
        containerItem.canvas.updateCanvasModel(false);
    };

    handleImportData = () => {
        const { element, selectionLayerController } = this.props;
        const containerItem = element.parentElement;

        selectionLayerController.setFreezeSelection(true);

        ShowDialog(LinkDataDialog, {
            element: containerItem,
            handleSelectedData: data => this.handleLinkData(data),
            handleDisconnect: () => this.handleUnlinkData(),
            onClose: () => selectionLayerController.setFreezeSelection(false)
        });
    };

    get DataSourceButton() {
        const { element } = this.props;

        const containerItem = element.parentElement;
        const hasDataSourceLink = containerItem.hasDataSourceLink();

        if (!hasDataSourceLink) {
            return (
                <Box height={40}>
                    <Button width={180} onClick={this.handleImportData}><Icon>trending_up</Icon>Import Data...</Button>
                </Box>
            );
        }

        return (
            <Box height={40}>
                <Popup icon="trending_up" label="Linked data settings" showArrow contained={false}>
                    <PopupContent>
                        {closePopup =>
                            <LinkedDataMenu element={containerItem} closeMenu={closePopup} />
                        }
                    </PopupContent>
                </Popup>
            </Box>
        );
    }

    render() {
        return null;
    }
}

import React, { Component } from "react";
import styled from "styled-components";

import * as geom from "js/core/utilities/geom";
import { DecorationStyle, ForeColorType, HorizontalAlignType, PaletteColorType, VerticalAlignType } from "common/constants";

import { Icon } from "../../../base/MediaElements/IconElement";
import { BaseElement } from "../../../base/BaseElement";
import { SVGCircleElement } from "../../../base/SVGElement";
import { TextElement } from "../../../base/Text/TextElement";
import { CheckListDecorationDefaultOverlay, IconListDecorationDefaultOverlay } from "../../../../Editor/ElementDefaultOverlays/ListDecorationDefaultOverlay";

class ListDecoration extends BaseElement {
    get bulletColorIndex() {
        return this.parentElement.getBulletColorIndex(this.model.bulletIndex ?? 0, this.model.indent ?? 0);
    }

    get canvasBounds() {
        const bounds = super.canvasBounds;

        if (this.parentElement.calculatedProps) {
            const blockProps = this.parentElement.calculatedProps.blockProps.findById(this.blockModel.id);
            return bounds
                .offset(0, this.parentElement.textBounds.top)
                .offset(0, -blockProps.normalizedTopSpace)
                .offset(0, blockProps.textBounds.top);
        }

        return bounds;
    }

    get defaultOverlayContainerZIndex() {
        return 999;
    }

    getSize(fontHeight) {
        return fontHeight * 2;
    }
}

export class BulletDecoration extends ListDecoration {
    _build() {
        this.bullet = this.addElement("bullet", () => SVGCircleElement);
    }

    _calcProps(props, options) {
        const { size } = props;

        const SCALE = .3;

        let bulletProps = this.bullet.calcProps(new geom.Size(size.height * SCALE, size.height * SCALE));

        if (options.textStyles.textAlign == HorizontalAlignType.RIGHT) {
            bulletProps.bounds = new geom.Rect(size.width - bulletProps.size.width, size.height / 2 - bulletProps.size.height / 2, bulletProps.size);
        } else {
            bulletProps.bounds = new geom.Rect(0, size.height / 2 - bulletProps.size.height / 2, bulletProps.size);
        }

        return { size };
    }

    get decorationStyle() {
        return DecorationStyle.FILLED;
    }

    _applyColors() {
        if (this.model.indent > 0) {
            this.colorSet.decorationColor = this.palette.getColor(this.model.color ?? "primary", this.getBackgroundColor(), { forceWhiteForPrimaryOnColor: true });
        } else {
            let bulletColor = this.model.color ?? this.getDecorationColor();
            if (bulletColor == "colorful") {
                bulletColor = this.palette.getColorfulColor(this.bulletColorIndex);
            }
            this.colorSet.decorationColor = this.palette.getColor(bulletColor, this.getBackgroundColor(), { forceWhiteForPrimaryOnColor: true });
        }
    }
}

export class NumberedListDecoration extends ListDecoration {
    _build() {
        this.index = this.addElement("index", () => TextElement, {
            html: "",
            verticalAlign: VerticalAlignType.MIDDLE,
            scaleTextToFit: false,
            canEdit: false,
            backgroundElement: this.decoration
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        let label;
        switch (this.options.listDecorationStyle) {
            case "letters":
                label = String.fromCharCode(64 + options.sequenceNum);
                break;
            case "numbers":
            default:
                label = options.sequenceNum;
                break;
        }

        if (this.model.indent > 0) {
            label += ".";
        }

        this.index.styles.textAlign = HorizontalAlignType.CENTER;
        this.index.styles.fontSize = options.textStyles.fontSize * 0.75;

        this.index.options.html = label;
        let indexProps = this.index.calcProps(size);
        indexProps.bounds = new geom.Rect(0, 0, indexProps.size);

        return { size };
    }

    get decorationStyle() {
        // Always use theme style
        return this.palette?.theme?.get("styleElementStyle");
    }

    _applyColors() {
        let bulletColor = this.blockModel.bulletColor ?? this.model.color ?? this.getDecorationColor();
        if (bulletColor == "colorful") {
            bulletColor = this.palette.getColorfulColor(this.bulletColorIndex);
        }

        if (this.canvas.getTheme().get("styleShape") == "none") {
            this.index.colorSet.fontColor = this.palette.getColor(bulletColor, this.getBackgroundColor());
        }
        this.colorSet.decorationColor = this.palette.getColor(bulletColor, this.getBackgroundColor(), { forceWhiteForPrimaryOnColor: true });
    }

    _renderElement(transition, renderProps = { animationName: null }) {
        // Force number text element to be on top of the main text element so its cursor style gets respected
        return super._renderElement(transition, { ...renderProps, zIndex: 10 });
    }
}

const SimpleNumberedListItem = styled.div`
  position: absolute;
  height: 100%;
  font-family: ${props => props.textStyles.fontFamily};
  font-size: ${props => props.textStyles.fontSize}px;
  color: ${props => props.textStyles.color};
  line-height: ${props => props.textStyles.lineHeight};
`;

export class SimpleNumberedDecoration extends Component {
    render() {
        const { style, textStyles, sequenceNum, indentSize, textBounds } = this.props;

        let label;
        switch (style) {
            case "letters":
                label = `${String.fromCharCode(64 + sequenceNum)}`;
                break;
            case "numbers":
            default:
                label = `${sequenceNum}`;
                break;
        }

        let styles = {
            width: indentSize,
        };

        if (textStyles.textAlign === HorizontalAlignType.RIGHT) {
            styles.left = textBounds.right - indentSize;
            styles.textAlign = HorizontalAlignType.LEFT;
            styles.paddingLeft = "0.6em";
            label = "." + label;
        } else {
            styles.left = 0;
            styles.textAlign = HorizontalAlignType.RIGHT;
            styles.paddingRight = "0.6em";
            label = label + ".";
        }

        return (
            <SimpleNumberedListItem
                textStyles={textStyles}
                style={styles}
            >
                {label}
            </SimpleNumberedListItem>
        );
    }
}

export class IconListDecoration extends ListDecoration {
    get _canSelect() {
        return true;
    }

    get iconId() {
        return this.model.iconId ?? "star";
    }

    get showDefaultOverlay() {
        return true;
    }

    getElementDefaultOverlay() {
        return IconListDecorationDefaultOverlay;
    }

    getSize(fontHeight) {
        return fontHeight * 2;
    }

    _build() {
        this.icon = this.addElement("icon", () => Icon, {
            icon: this.iconId,
            backgroundElement: this.decoration
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        let iconProps = this.icon.calcProps(size);
        iconProps.bounds = new geom.Rect(0, 0, iconProps.size);

        return { size };
    }

    getDecorationColor() {
        let bulletColor = this.blockModel.bulletColor ?? this.model.color ?? super.getDecorationColor();
        if (bulletColor == "colorful") {
            bulletColor = this.palette.getColorfulColor(this.bulletColorIndex);
        }
        return this.palette.getColor(bulletColor, this.getBackgroundColor());
    }
}

export class CheckListDecoration extends ListDecoration {
    get _canRollover() {
        return false;
    }

    get iconId() {
        switch (this.model.checkState) {
            case "unchecked":
                return "check-yes";
            case "crossed-out":
                return "x";
            case "checked":
            default:
                return "check-yes";
        }
    }

    get showDefaultOverlay() {
        return true;
    }

    getElementDefaultOverlay() {
        return CheckListDecorationDefaultOverlay;
    }

    _loadStyles(styles) {
        styles.pointerEvents = "auto";
        styles.zIndex = 10;
    }

    _build() {
        this.icon = this.addElement("icon", () => Icon, {
            icon: this.iconId,
            canSelect: false,
            doubleClickToSelect: false,
        });
    }

    _applyColors() {
        switch (this.model.checkState) {
            case "unchecked":
                this.icon.colorSet.iconColor = this.palette.getColor(ForeColorType.PRIMARY, this.getBackgroundColor()).setAlpha(0.5);
                break;
            case "crossed-out":
                this.icon.colorSet.iconColor = this.palette.getColor(PaletteColorType.NEGATIVE, this.getBackgroundColor());
                break;
            case "checked":
            default:
                this.icon.colorSet.iconColor = this.palette.getColor(PaletteColorType.POSITIVE, this.getBackgroundColor());
                break;
        }
    }
}

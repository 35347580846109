import { BlockStructureType, CalloutType, DecorationStyle, DirectionType, HorizontalAlignType, PositionType, TextStyleType } from "../../../../../../common/constants";
import * as geom from "../../../../../core/utilities/geom";

import { TextElement } from "../../base/Text/TextElement";
import { SVGCircleElement } from "../../base/SVGElement";
import { CalloutElement } from "./CalloutElement";
import { app } from "../../../../../namespaces";

export class MarkerCallout extends CalloutElement {
    get authoringElementType() {
        return CalloutType.MARKERANDTEXT;
    }

    get minMarkerSize() {
        return 10;
    }

    get maxMarkerSize() {
        return 400;
    }

    get minWidth() {
        return 150;
    }

    get minTextWidth() {
        return 150;
    }

    get selectionPadding() {
        return { top: 20, bottom: 20, left: 0, right: 0 };
    }

    get markerColor() {
        return this.model.color ?? this.findClosestOfType("CollectionElement").collectionColor;
    }

    getDefaultConnectorColor() {
        return this.markerColor;
    }

    get canChangeTextDirection() {
        return this.options.canChangeTextDirection ?? true;
    }

    getMarkerSize() {
        if (this.model.calloutType === CalloutType.CONTENT_AND_TEXT) {
            let markerSize = Math.clamp(this.model.markerSize ||
                this.styles.markerSize || 10,
            this.minMarkerSize, this.maxMarkerSize);

            return new geom.Size(markerSize, markerSize);
        }

        return new geom.Size(this.styles.markerSize, this.styles.markerSize);
    }

    get connectionShape() {
        return {
            bounds: this.marker.offsetBounds,
            type: "circle"
        };
    }

    get anchorBounds() {
        return this.marker.offsetBounds.offset(-this.styles.paddingLeft || 0, -this.styles.paddingTop || 0);
    }

    get fitToContents() {
        return {
            width: true,
            height: true
        };
    }

    get referencePoint() {
        return this.marker.offsetBounds.center;
    }

    get referencePointAnchor() {
        switch (this.textDirection) {
            case DirectionType.TOP:
                return geom.AnchorType.BOTTOM;
            case DirectionType.BOTTOM:
                return geom.AnchorType.TOP;
            case DirectionType.LEFT:
                return geom.AnchorType.RIGHT;
            case DirectionType.RIGHT:
            case DirectionType.AUTO:
                return geom.AnchorType.LEFT;
        }
    }

    get textDirection() {
        return this.model.textDirection ?? DirectionType.AUTO;
    }

    get resizeDirections() {
        return {
            left: this.textDirection !== DirectionType.RIGHT,
            right: this.textDirection !== DirectionType.LEFT,
            top: false,
            bottom: false
        };
    }

    getAutoTextDirection() {
        // if (app.isDraggingItem) {
        //     return this.calculatedTextDirection || DirectionType.RIGHT;
        // }

        if (this.connectorsFromNode.length > 0 || this.connectorsToNode.length > 0) {
            const nodePoint = new geom.Point(this.model.x, this.model.y);

            let anchorPoint;
            if (this.connectorsFromNode.length > 0) {
                const connector = this.connectorsFromNode[0];
                if (connector.endTarget) {
                    if (connector.endTarget.getAnchorPoint) {
                        anchorPoint = connector.endTarget.getAnchorPoint(connector, connector.startAnchor, nodePoint, connector.type, false);
                    } else if (connector.endTarget.anchorBounds) {
                        anchorPoint = connector.endTarget.anchorBounds.center;
                    } else {
                        anchorPoint = new geom.Rect(0, 0, connector.endTarget.size).center;
                    }
                } else {
                    anchorPoint = new geom.Point(connector.model.targetPoint);
                }
            } else {
                const connector = this.connectorsToNode[0];
                if (connector.startTarget) {
                    if (connector.startTarget.getAnchorPoint) {
                        anchorPoint = connector.startTarget.getAnchorPoint(connector, connector.endAnchor, nodePoint, connector.type, true);
                    } else if (connector.startTarget.anchorBounds) {
                        anchorPoint = connector.startTarget.anchorBounds.center;
                    } else {
                        anchorPoint = new geom.Rect(0, 0, connector.startTarget.size).center;
                    }
                } else {
                    anchorPoint = new geom.Point(connector.model.startPoint);
                }
            }

            let calculatedTextDirection;
            const nodeAngle = anchorPoint.angleToPoint(nodePoint);
            if (nodeAngle > 300 || nodeAngle < 60) {
                calculatedTextDirection = DirectionType.RIGHT;
            } else if (nodeAngle > 240 && nodeAngle <= 300) {
                calculatedTextDirection = DirectionType.TOP;
            } else if (nodeAngle > 115 && nodeAngle <= 240) {
                calculatedTextDirection = DirectionType.LEFT;
            } else {
                calculatedTextDirection = DirectionType.BOTTOM;
            }

            // If not dragging, then just return the calculated direction
            if (!this.isDragging) {
                return calculatedTextDirection;
            }

            // Direction hasn't changed
            if (this.calculatedTextDirection === calculatedTextDirection) {
                return calculatedTextDirection;
            }

            if (!this.dragWidgetPosition) {
                return PositionType.RIGHT;
            }

            const dragWidgetAngle = anchorPoint.angleToPoint(this.dragWidgetPosition);
            // Won't be changing direction if the angle to the drag widget changed less than
            // by 30 degrees
            if (this.textDirectionChangedAtAngle && Math.abs(this.textDirectionChangedAtAngle - dragWidgetAngle) < 30) {
                return this.calculatedProps?.textDirection;
            }

            // Changing direction
            this.textDirectionChangedAtAngle = dragWidgetAngle;
            return calculatedTextDirection;
        } else {
            return DirectionType.RIGHT;
        }
    }

    buildMarker() {
        return this.addElement("marker", () => SVGCircleElement);
    }

    textAlign(textDirection) {
        switch (textDirection) {
            case DirectionType.TOP: return HorizontalAlignType.CENTER;
            case DirectionType.BOTTOM: return HorizontalAlignType.CENTER;
            case DirectionType.LEFT: return HorizontalAlignType.RIGHT;
            case DirectionType.RIGHT: return HorizontalAlignType.LEFT;
        }
    }

    calcPropsText(textDirection) {
        return this.text.calcProps(new geom.Size(this.textWidth, this.canvas.CANVAS_HEIGHT), {
            textAlign: this.textAlign(textDirection),
            scaleTextToFit: true
        });
    }

    _build() {
        this.marker = this.buildMarker();
        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.HEADER,
            defaultBlockTextStyle: TextStyleType.TITLE,
            showFontSize: false,
            allowedBlockTypes: this.options.allowedTextBlockTypes ?? null,
            autoHeight: true,
            isTextFit: true,
            autoWidth: false,
            canAddBlocks: this.canAddBlocks,
            syncFontSizeWithSiblings: this.syncFontSizeWithSiblings,
            doubleClickToSelect: true
        });
    }

    _calcProps(props) {
        const { size } = props;

        this.marker.updateStyles({ fillColor: this.markerColor });

        const markerSize = this.getMarkerSize(size);
        const markerProps = this.marker.calcProps(markerSize);

        let textDirection = this.textDirection;
        if (textDirection === DirectionType.AUTO) {
            textDirection = this.getAutoTextDirection(size);
        }

        const textProps = this.calcPropsText(textDirection);

        switch (textDirection) {
            case DirectionType.TOP: {
                const middleLine = Math.max(markerProps.size.width, textProps.size.width) / 2;
                textProps.bounds = new geom.Rect(middleLine - textProps.size.width / 2, 0, textProps.size);
                markerProps.bounds = new geom.Rect(middleLine - markerProps.size.width / 2, textProps.size.height + this.styles.markerGap, markerProps.size);

                let calculatedSize = markerProps.bounds.union(textProps.bounds).size;
                return { size: calculatedSize };
            }
            case DirectionType.BOTTOM: {
                const middleLine = Math.max(markerProps.size.width, textProps.size.width) / 2;
                textProps.bounds = new geom.Rect(middleLine - textProps.size.width / 2, markerProps.size.height + this.styles.markerGap, textProps.size);
                markerProps.bounds = new geom.Rect(middleLine - markerProps.size.width / 2, 0, markerProps.size);

                let calculatedSize = markerProps.bounds.union(textProps.bounds).size;
                return { size: calculatedSize };
            }
            case DirectionType.LEFT: {
                if (markerProps.size.height > textProps.size.height) {
                    textProps.bounds = new geom.Rect(0, Math.max(0, markerProps.size.height / 2 - textProps.size.height / 2), textProps.size);
                    markerProps.bounds = new geom.Rect(textProps.size.width + this.styles.markerGap, Math.max(0, textProps.size.height / 2 - markerProps.size.height / 2), markerProps.size);
                } else {
                    let lineHeight = textProps.blockProps[0].fontHeight;
                    let middleLine = Math.max(markerProps.size.height, lineHeight) / 2;
                    textProps.bounds = new geom.Rect(0, middleLine - lineHeight / 2, textProps.size);
                    markerProps.bounds = new geom.Rect(textProps.size.width + this.styles.markerGap, middleLine - markerProps.size.height / 2 + this.styles.paddingTop, markerProps.size);
                }

                let calculatedSize = markerProps.bounds.union(textProps.bounds).size;
                return { size: calculatedSize };
            }
            case DirectionType.RIGHT:
            default: {
                if (markerProps.size.height > textProps.size.height) {
                    textProps.bounds = new geom.Rect(markerSize.width + this.styles.markerGap, Math.max(0, markerProps.size.height / 2 - textProps.size.height / 2), textProps.size);
                    markerProps.bounds = new geom.Rect(0, Math.max(0, textProps.size.height / 2 - markerProps.size.height / 2), markerProps.size);
                } else {
                    let lineHeight = textProps.blockProps[0].fontHeight;
                    let middleLine = Math.max(markerProps.size.height, lineHeight) / 2;
                    markerProps.bounds = new geom.Rect(0, middleLine - markerProps.size.height / 2 + this.styles.paddingTop, markerProps.size);
                    textProps.bounds = new geom.Rect(markerProps.size.width + this.styles.markerGap, middleLine - lineHeight / 2, textProps.size);
                }

                let calculatedSize = markerProps.bounds.union(textProps.bounds).size;
                return { size: calculatedSize };
            }
        }
    }

    _applyColors() {
        this.marker.colorSet.decorationColor = this.palette.getColor(this.markerColor, this.getBackgroundColor(), { itemIndex: this.itemIndex, forceWhiteForPrimaryOnColor: true });
    }
}

import React, { Component } from "react";
import styled from "styled-components";

import { themeColors } from "../../../../../../../react/sharedStyles";

import { BoundsBox } from "../../SelectionBox";
import { BlockTypePopupMenu } from "./TextFormatBar";

import { ItemColorPicker } from "../../../../EditorComponents/ColorPickers/ItemColorPicker";
import { ImageFramePopupMenu } from "../../../../EditorComponents/ImageFrameMenu";
import { MediaPopup } from "../../../../EditorComponents/MediaPopup";
import { ControlBar, ControlBarGroup } from "../../../../ElementControlBars/Components/ControlBar";
import { ControlBarPosition } from "../../../../../../../../common/constants";
import { ColorPicker } from "../../../../EditorComponents/ColorPickers/ColorPicker";

export const StyledControlBarGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;
    background: ${themeColors.ui_blue};
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;

    pointer-events: auto;

    border-radius: 3px;

    text-wrap: nowrap;

    & > div, .bai-button {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .bai-button {
        border: none !important;
        background: ${themeColors.ui_blue};
    }

    .withlabel {
        padding-left: 15px;

        .label-container {
            label {
                color: white;
            }
        }
    }

    & > div, & > button {
        color: white !important;
    }

    .bai-slider {
        padding: 0px 14px;

        .MuiSlider-root {
            color: #f1f1f1;
            width: 120px;
        }
    }

    .bai-popup {
        padding: 0px 10px;

        &.labeled {
            background: none;
            border: none;
        }
    }

    .bai-icon-button {
        padding: 0px 10px;
    }

    .MuiIcon-root, .bai-icon {
        font-size: 20px !important;
        color: white !important;
    }

    .MuiButton-root {
        height: auto !important;
        padding: 6px 12px;

        .MuiButton-label {
            letter-spacing: 0.4px;
            font-family: "Source Sans Pro", sans-serif;
            font-size: 12px;
            font-weight: 600;
            column-gap: 6px;
        }
    }
`;

export class MediaBlockEditor extends Component {
    handleChangeBackgroundColor = color => {
        const { element, block } = this.props;

        block.model.backgroundColor = color;

        element.canvas.updateCanvasModel();
    }

    handleToggleFullBleed = value => {
        const { block, refreshCanvasAndSaveChanges } = this.props;
        block.model.fullBleed = value;
        refreshCanvasAndSaveChanges();
    }

    render() {
        const { bounds, block, editorConfig, refreshCanvasAndSaveChanges, element } = this.props;

        const blockElement = block.element;

        return (
            <BoundsBox bounds={bounds} className="element-block-editor-container">
                <ControlBar position={ControlBarPosition.ABOVE}>
                    <ControlBarGroup color={themeColors.darkBlue}>
                        <BlockTypePopupMenu
                            selectedBlocks={[block]}
                            allowedBlockTypes={editorConfig.allowedBlockTypes}
                            onChange={refreshCanvasAndSaveChanges}
                        />
                    </ControlBarGroup>
                    <ControlBarGroup>
                        <MediaPopup element={blockElement.media.content} />
                        <ImageFramePopupMenu position="above"
                            element={blockElement}
                            allowedCategories={["shape", "device"]}
                            onChange={frameType => blockElement.updateModel({ frameType })}
                        />
                        <ColorPicker canvas={element.canvas} value={blockElement.model.color} size={20}
                            showPrimary showBackgroundColors showDecorationStyles
                            allowColorOnColor
                            onChange={color => blockElement.updateModel({ color })}
                            onChangeDecorationStyle={decorationStyle => blockElement.updateModel({ decorationStyle })}
                        />
                        {/*<ItemColorPicker element={blockElement} showDecorationStyles position="above" allowColorOnColor />*/}
                    </ControlBarGroup>
                </ControlBar>
            </BoundsBox>
        );
    }
}

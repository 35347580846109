import * as geom from "js/core/utilities/geom";
import { _ } from "js/vendor";

import { CollectionElement } from "../../base/CollectionElement";
import ConnectorItem from "./ConnectorItem";
import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.ELEMENTS);

export default class ConnectorGroup extends CollectionElement {
    getChildItemType() {
        return this.options.ConnectorItem ?? ConnectorItem;
    }

    getChildOptions(model) {
        const options = this.options.getConnectorOptions ? this.options.getConnectorOptions(model) : {};
        return {
            canDelete: this.canDeleteConnectors,
            defaultConnectorColor: this.options.defaultConnectorColor,
            ...options,
        };
    }

    getElementSelection() {
        return null;
    }

    get minItemCount() {
        return 0;
    }

    get _canSelect() {
        return false;
    }

    getTargetElement(id) {
        if (id.contains("/")) {
            return this.canvas.getElementByUniquePath(id);
        } else {
            return this.parentElement.elements[id];
        }
    }

    get connectors() {
        if (!this._connectors) {
            this._connectors = [];
        }
        return this._connectors;
    }

    get startPointsAreLocked() {
        return _.defaultTo(this.options.startPointsAreLocked, false);
    }

    get endPointsAreLocked() {
        return _.defaultTo(this.options.endPointsAreLocked, false);
    }

    get canDeleteConnectors() {
        return _.defaultTo(this.options.canDeleteConnectors, true);
    }

    get canAddLabels() {
        return _.defaultTo(this.options.canAddLabels, true);
    }

    getConnectorsForItem(itemId, type) {
        if (type == "source") {
            return this.itemElements.filter(Boolean).filter(connector => connector.model.source == itemId);
        } else {
            return this.itemElements.filter(Boolean).filter(connector => connector.model.target == itemId);
        }
    }

    addConnector(options) {
        return this.addItem(options);
    }

    setupElement() {
        this.containerElement = this.options.containerElement;
    }

    _build() {
        // Remove broken and/or removed connectors
        this.model.items = this.itemCollection
            .filter(Boolean)
            .filter(({ source, target, sourcePoint, targetPoint }) => (source || sourcePoint) && (target || targetPoint));

        this.buildItems();
    }

    _calcProps(props, options) {
        let { size } = props;

        for (let item of this.itemElements) {
            try {
                let itemProps = item.calcProps(size, { shape: options.shape });
                itemProps.bounds = new geom.Rect(0, 0, size);
            } catch (err) {
                logger.error(err, "Error calculating connector props");
                this.itemCollection.remove(item.model);
            }
        }

        return { size };
    }

    _applyColors() {
        for (let connector of this.itemElements) {
            connector.colorSet = { ...this.colorSet, ...connector.colorSet };
            connector.applyColors();
        }
    }

    removeElement(element) {
        if (element instanceof ConnectorItem && element.animator) {
            element.animator = null;
        }

        return super.removeElement(element);
    }

    _exportToSharedModel() {
        const graphData = [{
            connectors: this.itemElements.map(itemElement => ({
                id: itemElement.model.id,
                source: itemElement.model.source,
                target: itemElement.model.target,
                type: itemElement.model.connectorType,
                labelText: itemElement.labels?.length && itemElement.labels[0].blocks[0].textContent,
                props: _.omit(itemElement.model, ["source", "target", "connectorType", "labels"])
            }))
        }];

        return { graphData };
    }

    _migrate_10_02() {
        // suppress CollectionElement migration
    }
}

import styled from "styled-components";

import { themeColors } from "../../react/sharedStyles";

export const PropertyPanelHeader = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0px 15px;
    background: ${props => props.isChildElement ? themeColors.darkBlue : "white"};
    color: ${props => props.isChildElement ? "white" : "#333"};
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-shrink: 0;
    cursor: default;
`;

import { AuthoringCanvas } from "../AuthoringCanvas";
import { AuthoringElementType, CalloutType } from "../../../../../../common/constants";
import * as geom from "../../../../../core/utilities/geom";
import { _ } from "../../../../../vendor";
import ConnectorGroup from "../connectors/ConnectorGroup";
import { CalloutsCanvasSelection } from "../../../Editor/ElementSelections/CalloutsCanvasSelection";

export class CalloutsCanvas extends AuthoringCanvas {
    get isCallouts() {
        return true;
    }

    get name() {
        return "Callouts";
    }

    get _canSelect() {
        return false;
    }

    get restrictElementsToBounds() {
        return true;
    }

    get restrictElementsToBoundsByRegistrationPoint() {
        return true;
    }

    getAllowedCalloutTypes() {
        return [CalloutType.BOX, CalloutType.CIRCLE, CalloutType.TEXT, CalloutType.BULLET_TEXT, CalloutType.NUMBERED_TEXT, CalloutType.CONTENT_AND_TEXT, CalloutType.CONTENT, CalloutType.LINE_ARROW];
    }

    get allowCopyPasteStyles() {
        return false;
    }

    get allowConnectors() {
        return true;
    }

    get canDeleteConnectors() {
        return true;
    }

    get canPasteImage() {
        return false;
    }

    getElementSelection() {
        return CalloutsCanvasSelection;
    }

    getConnectorOptions(model) {
        return {
            canDragConnectors: model.sourcePoint && model.targetPoint,
            restrictConnectorToBounds: true,
            canConnectToOtherNodes: false
        };
    }

    get defaultConnectorColor() {
        return null;
    }

    snapPointToGrid(pt) {
        return new geom.Point(Math.round(pt.x / this.gridSize) * this.gridSize, Math.round(pt.y / this.gridSize) * this.gridSize);
    }

    _build() {
        super._build();

        if (!this.model.connections) {
            this.model.connections = {
                items: []
            };
        }

        if (!this.model.connections.items) {
            this.model.connections.items = [];
        }

        this.connectors = this.addElement("connectors", () => ConnectorGroup, {
            model: this.model.connections,
            containerElement: this,
            allowConnectors: this.allowConnectors,
            startPointsAreLocked: this.startPointsAreLocked,
            endPointsAreLocked: this.endPointsAreLocked,
            canDeleteConnectors: this.canDeleteConnectors,
            getConnectorOptions: this.getConnectorOptions,
            defaultConnectorColor: this.defaultConnectorColor,
        });
        this.connectors.layer = -1;
    }

    _calcProps(props, options) {
        const { size } = super._calcProps(props, options);
        let connectorProps = this.connectors.calcProps(size);
        connectorProps.opacity = 0.5;
        return { size, connectorProps };
    }

    _applyColors() {
        super._applyColors();
        this.connectors.applyColors();
    }

    _postCalcProps() {
        super._postCalcProps();
        this._migrate_10_02_postCalcProps();
    }

    _migrate_10_02() {
        this.model.collectionColor = this.canvas.getSlideColor();
        // migrate from old annotations model to callouts
        if (this.model.items) {
            this.model.elements = [];

            for (let annotation of this.model.items) {
                let model = {
                    ...annotation,
                    type: AuthoringElementType.CALLOUT,
                    calloutType: annotation.nodeType ?? CalloutType.BULLET_TEXT,
                    x: 0, y: 0, width: 1280, height: 1280,
                    // store position and size properties to handle migration post calc props
                    propsToMigrate: {
                        x: annotation.x ?? 0,
                        y: annotation.y ?? 0,
                        textWidth: annotation.textWidth,
                        size: annotation.size
                    }
                };

                for (let block of model.text?.blocks ?? []) {
                    block.accentColor = annotation.color ?? this.canvas.getSlideColor();
                }

                this.model.elements.push(model);
            }

            delete this.model.items;
        }
    }

    _migrate_10_02_postCalcProps() {
        let didMigrate = false;
        for (let element of Object.values(this.elements)) {
            if (element.model.propsToMigrate) {
                didMigrate = true;
                let pt = new geom.Point(this.calculatedProps.size.width * element.model.propsToMigrate.x, this.calculatedProps.size.height * element.model.propsToMigrate.y);
                if (this.model.snapToGrid) {
                    pt = this.snapPointToGrid(pt);
                }
                element.model.x = pt.x;
                element.model.y = pt.y;

                if (element.model.calloutType == CalloutType.CONTENT) {
                    element.model.width = element.model.height = element.model.propsToMigrate.size ?? 75;
                } else if (element.childElement.text) {
                    let textProps = element.childElement.text.calcProps(new geom.Size(element.model.propsToMigrate.textWidth ?? 200, 720), {
                        autoWidth: element.model.propsToMigrate.textWidth == null
                    });

                    switch (element.model.calloutType) {
                        case CalloutType.TEXT:
                            element.model.width = textProps.size.width + 20;
                            break;
                        case CalloutType.BULLET_TEXT:
                        case CalloutType.NUMBERED_TEXT:
                            element.model.width = textProps.size.width + 20 + 14;
                            break;
                        case CalloutType.CONTENT_AND_TEXT:
                            element.model.markerSize = element.model.propsToMigrate.size ?? 75;
                            element.model.width = textProps.size.width + (element.model.propsToMigrate.size ?? 75) + 40;
                            break;
                        case CalloutType.BOX:
                        case CalloutType.CAPSULE:
                        case CalloutType.FLEX_CIRCLE:
                        case CalloutType.DIAMOND:
                        case CalloutType.CIRCLE:
                            element.model.width = textProps.size.width + 40;
                            break;
                    }
                } else {
                    element.model.width = 200;
                }

                if (element.model.calloutType === CalloutType.CIRCLE) {
                    element.model.height = element.model.width;
                    element.model.fitToText = false;
                } else {
                    element.model.fitToText = true;
                }

                delete element.model.propsToMigrate;
            }
        }

        if (didMigrate) {
            this.canvas.layouter.runPostRender(() => this.canvas.refreshCanvas());
        }
    }

    deleteItem(itemId) {
        // delete any connectors that referenced the deleted item
        _.remove(this.model.connections.items, connector => connector.source == itemId);
        super.deleteItem(itemId);
    }

    _exportToSharedModel() {
        return { callouts: this.model };
    }

    _importFromSharedModel(model) {
        return model.callouts;
    }
}


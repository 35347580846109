import { _ } from "../../../../vendor";
import React, { Component } from "react";

import { ImageOption } from "../../../../Components/ImageOptionList";
import { ImagePopup } from "../../../../Components/ImagePopup";
import { StaticImage } from "../../../../Components/StaticImage";
import { ToggleButton, ToggleButtons } from "../../../../Components/ToggleButtons";
import { ToggleSwitch } from "../../../../Components/ToggleSwitch";
import { WithLabel } from "../../../../Components/WithLabel";
import { ShowWarningDialog } from "../../../../react/components/Dialogs/BaseDialog";

import { PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { CollectionColorPicker } from "../EditorComponents/ColorPickers/CollectionColorPicker";
import { ColorPicker } from "../EditorComponents/ColorPickers/ColorPicker";
import { Slider } from "../../../../Components/Slider";
import { OrientationType } from "common/constants";

export class OrgChartPropertyPanel extends Component {
    handleChangeLayoutDirection = value => {
        const { element } = this.props;

        const ERROR_TITLE = "Sorry, we are unable to switch the layout direction";

        if (value === OrientationType.HORIZONTAL && _.some(element.itemElements, node => node.isAssistant)) {
            return ShowWarningDialog({
                title: ERROR_TITLE,
                message: "Assistant nodes are not supported in horizontal layout",
            });
        }

        if (value === OrientationType.HORIZONTAL && element.model.layout.equalsAnyOf("stacked", "compressed")) {
            element.model.layout = "expanded";
        }
        if (value === OrientationType.HORIZONTAL && element.model.defaultNodeStyle == "minimal") {
            element.model.defaultNodeStyle = "box";
        }
        if (value === OrientationType.HORIZONTAL && element.model.defaultNodeStyle.equalsAnyOf("photo2", "photo3")) {
            element.model.defaultNodeStyle = "photo1";
        }

        element.model.layoutDirection = value;
        element.markStylesAsDirty();
        element.canvas.updateCanvasModel(true).catch(err => {
            if (value === OrientationType.VERTICAL) {
                // if we couldn't switch from expanded horizontal to expanded vertical, try compressed vertical
                element.model.layoutDirection = value;
                element.model.layout = "compressed";
                element.canvas.updateCanvasModel(true).catch(() => {
                    ShowWarningDialog({
                        title: ERROR_TITLE,
                        message: "Layout won't fit in this orientation. Try removing one or more nodes from the org chart.",
                    });
                });
            } else {
                ShowWarningDialog({
                    title: ERROR_TITLE,
                    message: "Layout won't fit in this orientation. Try removing one or more nodes from the org chart.",
                });
            }
        });
    }

    render() {
        const { element, selectionLayerController } = this.props;

        return (
            <PropertyPanelContainer>
                {element.model.layout != "table" && (
                    <PropertySection>
                        <WithLabel label="Style">
                            <ImagePopup
                                value={element.model.defaultNodeStyle}
                                size={100}
                                onChange={value => {
                                    selectionLayerController.setSelectedElements([]);
                                    element.updateModel({ defaultNodeStyle: value });
                                }}
                            >
                                <ImageOption value="minimal" label="Minimal" disabled={element.model.layoutDirection !== "vertical"}>
                                    <StaticImage src="/images/ui/orgchart/style_minimal.svg" />
                                </ImageOption>
                                <ImageOption value="box" label="Box">
                                    <StaticImage src="/images/ui/orgchart/style_box.svg" />
                                </ImageOption>
                                <ImageOption value="photo1" label="Photo1">
                                    <StaticImage src="/images/ui/orgchart/style_photo1.svg" />
                                </ImageOption>
                                <ImageOption value="photo2" label="Photo2" enabled={element.model.layoutDirection == "vertical"}>
                                    <StaticImage src="/images/ui/orgchart/style_photo2.svg" />
                                </ImageOption>
                                <ImageOption value="photo3" label="Photo3"
                                    disabled={
                                        element.canvas.model.layout.headerPosition === "top" && element.rowCount > 3 ||
                                        element.rowCount > 4 ||
                                        element.layoutDirection === OrientationType.HORIZONTAL
                                    }
                                >
                                    <StaticImage src="/images/ui/orgchart/style_photo3.svg" />
                                </ImageOption>
                            </ImagePopup>
                        </WithLabel>
                    </PropertySection>
                )}
                <PropertySection>
                    <WithLabel label="Node Color">
                        <CollectionColorPicker element={element} showDecorationStyles />
                    </WithLabel>
                    <WithLabel label="Connector Color">
                        <ColorPicker value={element.connectorColor} canvas={element.canvas}
                            onChange={value => element.updateModel({ connectorColor: value })}
                            showPrimary showSecondary
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Layout">
                        <ImagePopup value={element.model.layout}
                            size={120} cols={4} previewSize={70}
                            onChange={value => {
                                if (value == "table" && _.some(element.itemElements, node => node.isAssistant)) {
                                    return ShowWarningDialog({
                                        title: "Sorry, we can't make this change",
                                        message: "Assistant nodes are not supported in table layout",
                                    });
                                }
                                selectionLayerController.setSelectedElements([]);

                                // If the layout is horizontal and the new layout is stacked, change the layout direction to vertical
                                if (element.model.layoutDirection === OrientationType.HORIZONTAL && value === "stacked") {
                                    element.model.layoutDirection = OrientationType.VERTICAL;
                                }
                                element.model.layout = value;
                                element.model.verticalSpacing = element.model.horizontalSpacing = null;

                                element.canvas.updateCanvasModel(true).catch(() => {
                                    ShowWarningDialog({
                                        title: "Sorry, we can't make this change",
                                        message: "The org chart will not fit on this slide with that layout",
                                    });
                                });
                            }}
                        >
                            <ImageOption value="expanded" label="Expanded">
                                <StaticImage src="/images/ui/orgchart/org_expanded.svg" />
                            </ImageOption>
                            <ImageOption value="compressed" label="Collapsed"
                                disabled={element.model.layoutDirection !== OrientationType.VERTICAL}>
                                <StaticImage src="/images/ui/orgchart/org_collapsed.svg" />
                            </ImageOption>
                            <ImageOption value="stacked" label="Stacked"
                                disabled={element.model.layoutDirection !== OrientationType.VERTICAL}>
                                <StaticImage src="/images/ui/orgchart/org_stacked.svg" />
                            </ImageOption>
                            <ImageOption value="table" label="Table">
                                <StaticImage src="/images/ui/orgchart/org_table.svg" />
                            </ImageOption>
                        </ImagePopup>
                    </WithLabel>
                    <WithLabel label="Direction">
                        <ToggleButtons
                            exclusive
                            value={element.model.layoutDirection ?? OrientationType.VERTICAL}
                            onChange={this.handleChangeLayoutDirection}
                        >
                            <ToggleButton value={OrientationType.VERTICAL}>Vertical</ToggleButton>
                            <ToggleButton disabled={element.model.layout === "stacked"} value={OrientationType.HORIZONTAL}>Horizontal</ToggleButton>
                        </ToggleButtons>
                    </WithLabel>
                    <WithLabel label="Hide Root Node">
                        <ToggleSwitch value={element.hideRootNode}
                            onChange={value => {
                                element.model.hideRootNode = value;
                                element.markStylesAsDirty();
                                element.canvas.updateCanvasModel(true).catch(err => {
                                    ShowWarningDialog({
                                        title: "Sorry, we can't make this change",
                                        message: "Unable to show root node until some nodes are removed.",
                                    });
                                });
                            }}
                            disabled={element.model.layoutDirection !== OrientationType.VERTICAL ||
                                 (element.model.layout != "compressed" && element.model.layout !== "expanded") ||
                                 element.rootNode.assistantNodes.length > 0}
                        />
                    </WithLabel>
                </PropertySection>
                <PropertySection>
                    <WithLabel label="Vertical Spacing">
                        <Slider value={element.verticalSpacing}
                            min={20} max={250} step={1}
                            onChange={value => element.updateModel({ verticalSpacing: value })}
                            showInput
                        />
                    </WithLabel>
                    <WithLabel label="Horizontal Spacing">
                        <Slider value={element.horizontalSpacing}
                            min={0} max={250} step={1}
                            onChange={value => element.updateModel({ horizontalSpacing: value })}
                            showInput
                        />
                    </WithLabel>
                </PropertySection>

            </PropertyPanelContainer>
        );
    }
}


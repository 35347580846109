import { _, tinycolor } from "js/vendor";
import React, { Component } from "react";
import { v4 as uuid } from "uuid";

import { mergeMediaElementModelDefaults } from "../../../../../../../common/assetUtils";
import { AssetType, AuthoringBlockType, ControlBarPosition, HorizontalAlignType, PaletteColorType, PositionType, ShapeType, TextStyleType, VerticalAlignType } from "../../../../../../../common/constants";
import { IconButton } from "../../../../../../Components/IconButton";
import { Popup, PopupContent, PopupPreview } from "../../../../../../Components/Popup";
import { Slider } from "../../../../../../Components/Slider";
import { WithLabel } from "../../../../../../Components/WithLabel";
import { ShowDialog } from "../../../../../../react/components/Dialogs/BaseDialog";
import { Gap20 } from "../../../../../../react/components/Gap";
import { FlexBox } from "../../../../../../react/components/LayoutGrid";
import { AddAssetsContainer } from "../../../../../../react/views/AddAssets/index";

import { Dropdown } from "../../../../../../Components/Dropdown";
import { Icon } from "../../../../../../Components/Icon";
import { MenuItem } from "../../../../../../Components/Menu";
import { ds } from "../../../../../../core/models/dataService";
import { PropertyPanelContainer, PropertySection, PropertySectionHeader } from "../../../../../../EditorComponents/PropertyPanel";
import { PropertyPanelButton } from "../../../../../../EditorComponents/PropertyPanelButton";
import { DeviceFrames } from "../../../../elements/DeviceFrames";
import { InfographicElementTypes } from "../../../../elements/elements/Dashboard/InfographicManager";
import { BackgroundColorPicker } from "../../../EditorComponents/ColorPickers/BackgroundColorPicker";
import { ColorPicker } from "../../../EditorComponents/ColorPickers/ColorPicker";
import { ImageFrameMenu, ImageFramePopupMenu } from "../../../EditorComponents/ImageFrameMenu";
import { MediaPopup } from "../../../EditorComponents/MediaPopup";
import { ControlBar, ControlBarButton } from "../../../ElementControlBars/Components/ControlBar";
import { MediaPropertyPanel } from "../../MediaUI";
import { TextFrameBoxPropertyPanel } from "../../TextFrameBoxUI";
import { AddTextPopupMenu } from "./AddTextPopupMenu";

export async function setCellToTextAndImage(element) {
    if (element.model.componentType !== InfographicElementTypes.TEXT_AND_IMAGE) {
        element.model.componentType = InfographicElementTypes.TEXT_AND_IMAGE;
    }
    element.model.childElement ||= {};
    element.model.cellColor ||= PaletteColorType.BACKGROUND_ACCENT;
}

export async function addMediaToLayoutContainerItem({ element }) {
    setCellToTextAndImage(element);

    await ShowDialog(AddAssetsContainer, {
        callback: async model => {
            mergeMediaElementModelDefaults(element.model.childElement, model);

            // load asset to check for transparency
            if (model.content_type == AssetType.IMAGE || model.content_type == AssetType.LOGO) {
                const asset = await ds.assets.getAssetById(model.content_value);
                if (asset.get("hasSolidBackground")) {
                    // for assets with a solid background, set the cell color to the image background color
                    element.model.cellColor = tinycolor(asset.get("imageBackgroundColor")).toRgbString();
                } else if (model.content_type == AssetType.LOGO) {
                    // for logos, set the cell color to white by default
                    element.model.cellColor = "white";
                }
            }

            await element.canvas.updateCanvasModel(false);
            element.canvas.selectionLayerController.setSelectedElements([element]);
        },
    });
}

export async function addTextToLayoutContainerItem({ element, blocks = [], textAlign = HorizontalAlignType.CENTER, verticalAlign = VerticalAlignType.MIDDLE, autoSelect = true }) {
    // create text and image cell if it doesn't exist
    setCellToTextAndImage(element);

    // add default text if no blocks are provided
    const containerElement = element.findClosestOfType("GridLayoutContainer");

    if (blocks.length === 0) {
        blocks = [{
            id: uuid(), html: "", type: AuthoringBlockType.TEXT, textStyle: containerElement.defaultTextStyle ?? TextStyleType.HEADING
        }];
    }

    if (!element.model.childElement) {
        element.model.childElement = {};
    }

    // if the user tries to add text to a cell with an icon as it's content, convert the whole thing to text blocks
    if (element.childElement.showImage && element.childElement.content.assetType == AssetType.ICON) {
        blocks.insert({
            type: "media",
            blockHeight: 200,
            elementModel: {
                elementType: "MediaBlock",
                content_type: "icon",
                content_value: element.model.childElement.content_value,
                frameType: "none"
            }
        }, 0);
        element.childElement.content.deleteAsset();
    }

    element.model.childElement.text = { blocks: _.cloneDeep(blocks) };

    // remove user position and size
    delete element.model.childElement.userPositionX;
    delete element.model.childElement.userPositionY;
    delete element.model.childElement.userSize;

    element.model.childElement.boxHAlign = textAlign;
    element.model.childElement.textAlign = textAlign;
    element.model.childElement.verticalAlign = verticalAlign;

    if (element.childElement.showImage) {
        element.model.childElement.backdropStyle = "white_box";
        element.model.childElement.backdropColor = "white";
        element.model.childElement.backdropPadding = 30;
    } else {
        element.model.cellColor ||= containerElement.defaultCellColor;
    }

    await element.saveModel();
    if (autoSelect) {
        element.canvas.selectionLayerController.selectTextElementBlock(element.childElement.textFrame.text, blocks[0].id);
    }
}

export class TextAndImagePropertyPanel extends Component {
    handleAddMedia = () => {
        const { element } = this.props;
        ShowDialog(AddAssetsContainer, {
            callback: async model => {
                mergeMediaElementModelDefaults(element.model, model);
                await element.canvas.updateCanvasModel(false);
                element.canvas.selectionLayerController.setSelectedElements([element]);
            },
        });
    }

    handleAddText = async () => {
        const { element } = this.props;

        let blockId = uuid();
        element.model.text = {
            blocks: [{
                id: blockId, html: "", type: AuthoringBlockType.TEXT, textStyle: TextStyleType.HEADING
            }]
        };

        if (element.showImage) {
            element.model.backdropColor = "white";
        }

        await element.saveModel();
        element.canvas.selectionLayerController.selectTextElementBlock(element.textFrame.text, blockId);
    }

    handleSetIconPosition = value => {
        const { element } = this.props;

        switch (value) {
            case PositionType.INLINE:
                element.textFrame.text.model.text.blocks.insert({
                    id: uuid(),
                    type: AuthoringBlockType.MEDIA,
                    elementModel: {
                        elementType: "MediaBlock",
                        content_type: AssetType.ICON,
                        content_value: element.model.content_value
                    }
                }, 0);
                element.model.content_type = null;
                element.model.content_value = null;
                element.saveModel();
                break;
            case PositionType.BACKGROUND:
            case PositionType.LEFT:
            case PositionType.RIGHT:
            default:
                let model = {
                    iconPosition: value
                };
                if (element.model.content_value == null) {
                    let mediaBlock = element.textFrame.text.model.text.blocks.find(block => block.type == AuthoringBlockType.MEDIA);
                    element.textFrame.text.model.text.blocks.remove(mediaBlock);
                    model.content_type = AssetType.ICON;
                    model.content_value = mediaBlock.elementModel.content_value;
                    model.iconScale = 1;
                }
                element.updateModel(model);
                break;
        }
    }

    render() {
        const { element } = this.props;

        const hasShapeFrame = DeviceFrames.findById(element.model.frameType)?.category == "Shape";
        const allowBackgroundColor = element.allowBackgroundColor;
        const itemContainer = element.parentElement;
        const hasFrame = element.model.frameType && element.model.frameType !== "none";

        let iconPosition;
        if (element.showImage && element.showText && element.content?.assetType == AssetType.ICON) {
            iconPosition = element.model.iconPosition ?? PositionType.LEFT;
        } else if (!element.showImage && element.showText && element.textFrame?.text.model.text.blocks.find(block => block.type == AuthoringBlockType.MEDIA)) {
            iconPosition = PositionType.INLINE;
        }

        return (
            <PropertyPanelContainer>
                {element.showImage && element.content &&
                    <MediaPropertyPanel element={element.content} showAttribution showCaption />
                }

                {element.showText && element.textFrame &&
                    <TextFrameBoxPropertyPanel element={element.textFrame.textFrameBox} showDeleteButton allowAnimation />
                }

                {iconPosition &&
                    <PropertySection>
                        <WithLabel label="Icon Position">
                            <Dropdown value={iconPosition}
                                onChange={this.handleSetIconPosition}
                            >
                                <MenuItem value={PositionType.LEFT}>Left</MenuItem>
                                <MenuItem value={PositionType.RIGHT}>Right</MenuItem>
                                <MenuItem value={PositionType.INLINE}>Inline</MenuItem>
                                <MenuItem value={PositionType.BACKGROUND}>Background</MenuItem>
                            </Dropdown>
                        </WithLabel>
                    </PropertySection>
                }

                {hasFrame &&
                    <PropertySection>
                        <PropertySectionHeader label="Frame">
                            <IconButton icon="delete" tooltip="Remove Frame"
                                onClick={() => element.updateModel({ frameType: "none" }, { refreshStyles: true })} />
                        </PropertySectionHeader>
                        <FlexBox fillWidth left>
                            <ImageFramePopupMenu
                                size={40} showPreview
                                frameType={element.model.frameType}
                                allowedCategories={["shape", "device", "decorative"]}
                                onChange={frameType => element.updateModel({ frameType }, { refreshStyles: true })}
                                showRemoveFrameButton
                            />
                            <Gap20 />
                            {allowBackgroundColor &&
                                <WithLabel label={hasShapeFrame ? "Frame Color" : "Frame Background"}
                                    //        toolTip={
                                    // <>
                                    //     <p>Sets the background color within the frame.</p>
                                    //     <p>NOTE: The color will only be visible for images scaled smaller than the frame or content like text, icons, or images with transparency.</p>
                                    // </>}
                                >
                                    <ColorPicker value={element.model.frameColor ?? "theme"} canvas={element.canvas}
                                        onChange={color => element.updateModel({ frameColor: color }, { refreshStyles: true })}
                                        onChangeDecorationStyle={style => element.updateModel({ decorationStyle: style }, { refreshStyles: true })}
                                        showBackgroundColors allowColorOnColor showPrimary
                                        showDecorationStyles={element.model.frameType.equalsAnyOf(ShapeType.RECT, ShapeType.ROUNDED_RECT, ShapeType.CIRCLE, ShapeType.OCTAGON)}
                                    />
                                </WithLabel>
                            }
                        </FlexBox>
                        {/*{DeviceFrames.findById(element.model.frameType)?.category != "Shape" &&*/}
                        <WithLabel label="Frame Scale" flex>
                            <Slider value={element.model.frameScale ?? 1}
                                showInput percent
                                min={0.5} max={1.0} step={0.01}
                                onChange={value => element.refreshModel({ frameScale: value })}
                                onCommit={() => element.saveModel()}
                            />
                        </WithLabel>
                        {/*}*/}
                    </PropertySection>
                }

                {!element.showImage &&
                    <PropertyPanelButton icon="add_a_photo"
                        title="Add Media to Cell..."
                        description="Add an image, icon or video to fill the cell."
                        onClick={() => addMediaToLayoutContainerItem({ element: itemContainer })}
                    />
                }
                {!element.showText && element.allowText &&
                    <Popup>
                        <PopupPreview>
                            <PropertyPanelButton icon="text_increase"
                                title="Add Text to Cell"
                                description="Add text to the cell."
                            />
                        </PopupPreview>
                        <PopupContent>
                            {closePopup => <AddTextPopupMenu element={itemContainer} closePopup={closePopup} />}
                        </PopupContent>
                    </Popup>
                }

                {!hasFrame && (element.showImage || element.showText) &&
                    <Popup>
                        <PopupPreview>
                            <PropertyPanelButton icon="laptop_mac"
                                title="Add Frame to Cell"
                                description="Add a device or decorative frame to the cell."
                            />
                        </PopupPreview>
                        <PopupContent>
                            <ImageFrameMenu
                                selectedFrame={element.model.frameType}
                                allowedCategories={["shape", "device", "decorative"]}
                                onSelect={(frameType, aspectRatio, frame) => {
                                    if (element.content?.assetElement?.resetMediaTransform) {
                                        element.content.assetElement.resetMediaTransform();
                                    }
                                    element.updateModel({ frameType, frameScale: 0.9 }, { refreshStyles: true });
                                }}
                            />
                        </PopupContent>
                    </Popup>
                }
            </PropertyPanelContainer>);
    }
}

export class TextAndImageControlBar extends Component {
    render() {
        const { element } = this.props;

        if (!element.showImage && !element.showText) {
            return null;
        }

        const containerElement = element.parentElement;

        const imageBackgroundColor = element.imageBackgroundColor;

        let controlBarPosition = (containerElement.showTitle === "below") ? ControlBarPosition.BELOW : ControlBarPosition.INNER_BOTTOM;
        controlBarPosition = ControlBarPosition.BELOW;

        return (
            <ControlBar position={controlBarPosition}>
                {!element.showImage &&
                    <ControlBarButton onClick={() => addMediaToLayoutContainerItem({ element: containerElement })}>
                        <Icon>add_a_photo</Icon>
                    </ControlBarButton>
                }
                {element.showImage &&
                    <MediaPopup element={element.content} />
                }
                {!element.showText &&
                    <Popup icon="text_increase">
                        <PopupContent>
                            <AddTextPopupMenu element={containerElement} closePopup={this.closePopup} />
                        </PopupContent>
                    </Popup>
                }
                {element.allowBackgroundColor &&
                    <BackgroundColorPicker element={containerElement} colorProperty="cellColor" showNone size={18} imageBackgroundColor={imageBackgroundColor} />
                }
            </ControlBar>
        );
    }
}


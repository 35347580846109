import React from "react";
import styled from "styled-components";

import { AuthoringBlockType, DOUBLE_CLICK_THRESHOLD_MS, ListStyleType, TextStyleType } from "common/constants";
import { cjkFallbackFonts } from "common/fontConstants";
import { isOfflinePlayer, isRenderer } from "js/config";
import * as geom from "js/core/utilities/geom";
import ContentEditable from "js/react/components/ContentEditable";
import { themeColors } from "js/react/sharedStyles";
import { $, _, tinycolor } from "js/vendor";

import { ClipboardType, clipboardWrite } from "js/core/utilities/clipboard";
import { sanitizeHtml } from "js/core/utilities/dompurify";
import { DivGroup } from "js/core/utilities/svgHelpers";
import { AuthoringBlock } from "./AuthoringBlock";
import { BlockDecoration } from "./BlockDecoration";
import { SimpleNumberedDecoration } from "./ListDecorations";

const DEBUG_TEXT_LAYOUT = false;

const DebugBoundsBoxContainer = styled.div.attrs(({ bounds, color, thin }) => ({
    style: {
        ...bounds.toObject(),
        outline: `dotted ${thin ? "0.5" : "2"}px ${color}`
    }
}))`
  position: absolute;
  pointer-events: none;
`;

const DebugBoundsBoxLabel = styled.div.attrs(({ color }) => ({
    style: {
        color
    }
}))`
  position: absolute;
  top: 0;
  left: 100%;
  transform: translate(-100%, -100%);

  font-family: monospace;
  opacity: 0.5;
  letter-spacing: 2px;
  font-size: 14px;
`;

function DebugBoundsBox({ bounds, color, thin, name, showLabel = false }) {
    return (<DebugBoundsBoxContainer bounds={bounds} color={color} thin={thin}>
        {showLabel && <DebugBoundsBoxLabel color={color}>{name}</DebugBoundsBoxLabel>}
    </DebugBoundsBoxContainer>);
}

export const TextBlockContainer = styled.div`
  position: relative;
  width: 100%;
  font-size: 20px;
  pointer-events: auto;

  letter-spacing: 0px;
  
  ul, ol {
    margin: 0px;
    padding: 10px 0px 0px 40px;

    ul, ol {
      padding-top: 0px;
    }
  }

  li {
    margin-bottom: 20px;
  }

  a {
    color: ${(props => props.colors.hyperlink) ?? themeColors.ui_blue};
    text-decoration: none;
    pointer-events: auto;
    cursor: text;
  }

  .footnote {
    font-size: 0.5em;
    vertical-align: super;

    background: ${themeColors.ui_blue};
    border-radius: 50%;
    width: 1em;
    height: 1em;
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-family: Source Sans Pro;
    padding: 2px;
    cursor: pointer;
  }

  .isPlayback {
    a {
      cursor: pointer;
    }
  }

  .emphasized {
     font-weight: ${props => props.emphasizedFontWeight};
     color: ${props => props.colors.emphasized};
  }
  
  b {
    font-weight: ${props => props.emphasizedFontWeight};
  }

  .content-editable {
    position: relative;
    z-index: 1;
    outline: none;
    -webkit-user-select: auto;
    //font-feature-settings: "tnum";
    // TODO; commented out for split-text support - not sure if this is a problem elsewhere
    //div {
    //  margin: 10px 0px;
    //}
  }

  // needed for correct height measurement of empty text
  .content-editable:empty:before {
    content: "Spacer";
    pointer-events: none;
    display: block;
    opacity: 0;
  }

  // Legacy color
  .color-auto {
    color: ${props => props.colors.auto};
  }

  .color-primary {
    color: ${props => props.colors.primary};
  }

  .color-secondary {
    color: ${props => props.colors.secondary};
  }
`;

const Backdrop = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(-45deg, #D3E9F6, #D3E9F6 10px, #50bbe6 10px, #50bbe6 20px);
    opacity: 0.5;
`;

const Placeholder = styled.div.attrs(({ contentEditableStyles }) => ({
    style: {
        ...contentEditableStyles,
        color: tinycolor(contentEditableStyles.color).setAlpha(0.5).toRgbString(),
        // color: "#777",
        fontStyle: "italic",
        pointerEvents: "none",
        position: "absolute",
        top: 0,
        left: 0
    }
}))``;

export const PlaceholderActions = styled.div`
    gap: 10px;
    position: absolute;
    margin-left: 10px;
    margin-top: 2px;
    display: inline-flex;
`;

export function getListDecorationSpacing(blockProps) {
    if (blockProps.model.listStyle == ListStyleType.TEXT) {
        return 0;
    } else {
        return blockProps.fontHeight * blockProps.textStyles.bulletSpacing;
    }
}

export class TextBlock extends AuthoringBlock {
    get type() {
        return AuthoringBlockType.TEXT;
    }

    get blockMargin() {
        return {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        };
    }

    get bounds() {
        const { textStyles, bounds: calculatedBounds, canvas, containerRef } = this.props;

        let bounds = new geom.Rect(calculatedBounds.left * canvas.getScale(), calculatedBounds.top * canvas.getScale(), calculatedBounds.width * canvas.getScale(), calculatedBounds.height * canvas.getScale())
            .inflate(this.blockMargin)
            .inflate({ left: textStyles.marginLeft, right: textStyles.marginRight });

        if (containerRef.current) {
            const containerBounds = geom.Rect.FromBoundingClientRect(containerRef.current.getBoundingClientRect());
            bounds = bounds.offset(containerBounds.position);
        }

        return bounds;
    }

    get indent() {
        return this.props.indent ?? 0;
    }

    get textContent() {
        return this.ref.current.textContent;
    }

    get textStyle() {
        return this.props.textStyle;
    }

    constructor(props) {
        super(props);

        this.containerRef = React.createRef();
    }

    renderListDecoration(indentPadding, textAlign) {
        const { isCalculatingLayout, listDecorationElement, model, textStyles } = this.props;

        if (isCalculatingLayout) {
            return null;
        }

        if (model.listStyle == ListStyleType.NUMBERED && (!textStyles.allowFancyNumberedDecorations || model.useThemedListDecoration === false)) {
            return <SimpleNumberedDecoration {...this.props} indentSize={indentPadding} style={model.listDecorationStyle} />;
        }

        if (listDecorationElement?.calculatedProps) {
            return <DivGroup>{listDecorationElement.renderElement(false)}</DivGroup>;
        }

        return null;
    }

    renderBlockDecoration(textAlign) {
        const { textBounds, element, textStyles, isCalculatingLayout, renderProps: { bounds: renderBounds } } = this.props;

        if (isCalculatingLayout) {
            return null;
        }

        if (!textStyles.blockDecorationStyles) {
            return null;
        }

        return (
            <BlockDecoration
                bounds={renderBounds.zeroOffset()}
                textBounds={textBounds.offset(-renderBounds.position.x, -renderBounds.position.y)}
                textStyles={textStyles}
                textAlign={textAlign}
                styles={textStyles.blockDecorationStyles}
                element={element}
            />
        );
    }

    handleClick = event => {
        const { canvas } = this.props;

        const target = event.target;
        if (!target) {
            return;
        }

        // closest() will also return target if it is a link
        const linkNode = $(target).closest("a")[0];
        if (!linkNode) {
            return;
        }

        // Intercepting link clicks and forcing them to open in
        // new window
        event.stopPropagation();
        event.preventDefault();

        // Ignore clicks on links unless we are playing back
        if (!canvas.isPlayback) {
            return;
        }

        const href = linkNode.getAttribute("href");

        if (window.isPlayer && href.startsWith("bai://")) {
            const slideId = href.replace("bai://", "");
            const slideIndex = canvas.slide.presentation.getSlideIndex(slideId);
            if (!canvas.slide.presentation.getSips().includes(slideId)) return;

            canvas.playerView.goToSlide(slideIndex);
        } else {
            canvas.slide.presentation.openExternalUrl(href);
        }
    }

    handleCopy = event => {
        event.preventDefault();

        const range = window.getSelection().getRangeAt(0);

        const containerNode = this.ref.current;
        const containerStyle = window.getComputedStyle(containerNode);

        let styleNode = range.commonAncestorContainer;
        while (styleNode.nodeName === "#text") {
            styleNode = styleNode.parentElement;
        }

        let colorClass = null;
        let hasEmphasized = false;
        let classNode = styleNode;
        while (classNode !== containerNode) {
            if (!colorClass) {
                colorClass = [...classNode.classList.values()].find(className => className.startsWith("color-"));
            }
            if (!hasEmphasized) {
                hasEmphasized = [...classNode.classList.values()].some(className => className === "emphasized");
            }

            classNode = classNode.parentElement;
        }

        let contents = range.cloneContents();
        if (styleNode !== containerNode) {
            const style = window.getComputedStyle(styleNode);

            const fontStyle = style.getPropertyValue("font-style");
            const fontWeight = style.getPropertyValue("font-weight");
            const color = style.getPropertyValue("color");

            if (fontStyle === "italic") {
                const wrapper = document.createElement("i");
                wrapper.appendChild(contents);
                contents = wrapper;
            }

            if (fontWeight > containerStyle.getPropertyValue("font-weight")) {
                const wrapper = document.createElement("b");
                wrapper.appendChild(contents);
                contents = wrapper;
            }

            if (colorClass) {
                const wrapper = document.createElement("font");
                wrapper.setAttribute("class", colorClass);
                wrapper.appendChild(contents);
                contents = wrapper;
            } else if (color !== containerStyle.getPropertyValue("color") && color !== this.props.textStyles.emphasizedColor) {
                const wrapper = document.createElement("font");
                wrapper.style.setProperty("color", color);
                wrapper.appendChild(contents);
                contents = wrapper;
            }

            if (hasEmphasized) {
                const wrapper = document.createElement("font");
                wrapper.setAttribute("class", "emphasized");
                wrapper.appendChild(contents);
                contents = wrapper;
            }
        }

        const div = document.createElement("div");
        div.appendChild(contents);

        div.querySelectorAll("font").forEach(fontNode => {
            if (fontNode.classList.contains("emphasized")) {
                fontNode.setAttribute("data-block-accent-color", this.props.textStyles.accentColor);
            }
        });

        clipboardWrite({
            [ClipboardType.HTML]: div.innerHTML,
            [ClipboardType.TEXT]: div.innerHTML,
        });
    }

    setEditor = () => {
        this.setState({ hasEditor: true });
    }

    removeEditor = () => {
        this.setState({ hasEditor: false });
    }

    formatHtml(html) {
        html = html.replace("$", `<span class="text-superset">$</span>`);
        html = html.replace("%", `<span style="font-weight: 200">%</span>`);
        return html;
    }

    handleMouseDown = event => {
        const { element, selectionLayerController } = this.props;

        if (!element.canEditBlocks || !element.canSelect) {
            if (selectionLayerController && selectionLayerController.prevMouseDownAt + DOUBLE_CLICK_THRESHOLD_MS > Date.now()) {
                const position = new geom.Point(event.clientX, event.clientY);
                if (position.distance(selectionLayerController.prevMouseDownPosition) < 5) {
                    // Double clicked on ourselves, allow default so the caret gets set
                    return;
                }
            }

            event.preventDefault();
        }
    }

    handleFocus = () => {
        const { element } = this.props;

        if (!element.hasSelection) {
            // Selection layer decided to select a different element, force blur
            this.ref.current.blur();
        }
    }

    render() {
        const {
            model,
            textStyle,
            textStyles,
            textAlign,
            canvas,
            placeholder,
            blockPadding,
            isCalculatingLayout,
            containerBounds,
            lines,
            bounds,
            textBounds,
            ignoreSpellcheck,
            hasCanvasOverflow,
            showCanvasOverflow,
            indentWidth,
            parentBlock,
            element
        } = this.props;

        const {
            isDragging
        } = this.state;

        const isPlayback = canvas?.isPlayback;
        const isDisabled = !element.canEditBlocks || !element.canSelect;

        let html = model.html ?? "";

        // if there's no content, then show the placeholder
        let showPlaceholder = !_.trim(html).length;

        // if in playback mode then we don't want
        // to show the placeholder
        if (isPlayback && !canvas?.showTextPlaceholders) {
            showPlaceholder = false;
        }

        if (showPlaceholder) {
            // force empty html when showing placeholder
            html = "";
        }

        const textClasses = ["content-editable"];
        if (isPlayback) {
            textClasses.push("isPlayback");
        }

        const styles = {
            width: "100%",
            height: "100%",
            ...textStyles,
            cursor: element.canEditBlocks ? "text" : "default",
            pointerEvents: "auto",
            userSelect: isDisabled ? "none" : "text",
            marginTop: 0, // overrides margin in textStyles because it's handled at the TextBlockContainer
            marginBottom: 0,
        };
        if (isPlayback && model.linkToSlide) {
            styles.cursor = "pointer";
        }

        if (styles.maxLines === Number.POSITIVE_INFINITY) {
            delete styles.maxLines;
        }

        if (textStyles.textAlign == null) {
            styles.textAlign = textAlign;
        }

        if (model.textAlign && element.allowAlignment) {
            styles.textAlign = model.textAlign;
        }

        let indentPadding = indentWidth;

        if (model.listStyle && model.listStyle !== ListStyleType.TEXT) {
            // pad for list decoration spacing
            indentPadding += getListDecorationSpacing(this.props);
        }

        indentPadding += (textStyles.blockInset ?? 0);

        if (parentBlock && parentBlock.model.listStyle == ListStyleType.CHECKBOX && parentBlock.model.checkState == "unchecked") {
            styles.opacity = 0.5;
        }

        // Override padding for lists
        if (styles.textAlign === "left") {
            styles.padding = `0px 0px 0px ${indentPadding}px`;
        } else if (styles.textAlign === "right") {
            styles.padding = `0px ${indentPadding}px 0px 0px `;
        }

        if (styles.letterSpacing.startsWith("-")) {
            styles.paddingRight = styles.letterSpacing.substring(1);
        }

        styles.columnCount = model.columns ?? null;

        styles.fontKerning = (model.fontKerning ?? true) ? "auto" : "none";
        styles.hyphens = model.hyphenation ? "auto" : "none";
        styles.fontVariantLigatures = model.ligatures === false ? "none" : "normal";
        styles.fontVariantNumeric = "lining-nums";
        styles.overflowWrap = "normal";
        if (textStyles.isRTL) {
            styles.direction = "rtl";
        }

        if (hasCanvasOverflow) {
            styles.overflow = "hidden";
        }

        const emphasizedFontWeight = Math.clamp(textStyles.fontWeight + 200, 600, 900);
        const fontColor = textStyles.color;
        if (model.emphasized) {
            styles.color = textStyles.emphasizedColor;
            styles.fontWeight = emphasizedFontWeight;
        } else if (textStyles.hasEmphasized) {
            if (tinycolor.equals(textStyles.emphasizedColor, fontColor) || textStyle === TextStyleType.BODY) {
                styles.color = tinycolor(fontColor).setAlpha(0.5).toRgbString();
            }
        } else {
            styles.color = fontColor;
        }

        if (isRenderer || isOfflinePlayer) {
            // Set backup font families for renderer and electron to force it use the correct
            // fallback fonts (sans-serif and cjk fonts)

            const fallbackFonts = [
                "sans-serif",
                ...cjkFallbackFonts.map(({ fontFaceName }) => `'${fontFaceName}'`)
            ].join(",");

            styles.fontFamily = `${styles.fontFamily}, ${fallbackFonts}`;

            if (html) {
                const domparser = new DOMParser();
                const document = domparser.parseFromString(html, "text/html");
                document.querySelectorAll("*").forEach(element => {
                    if (element.style) {
                        const fontFamily = element.style.getPropertyValue("font-family");
                        if (fontFamily) {
                            element.style.setProperty("font-family", `${fontFamily}, ${fallbackFonts}`);
                        }
                    }
                });
                html = sanitizeHtml(document.body.innerHTML);
            }
        }

        if (isCalculatingLayout && showPlaceholder) {
            // When calculating layout, render placeholder text in the content editable
            // to measure correct bounds for the text
            html = placeholder.text;
            styles.fontStyle = "italic";
        }

        const backgroundColor = element.getBackgroundColor();
        const primary = backgroundColor.isDark() ? element.canvas.styleSheet.variables.color_primary_light : element.canvas.styleSheet.variables.color_primary_dark;
        const secondary = backgroundColor.isDark() ? element.canvas.styleSheet.variables.color_secondary_light : element.canvas.styleSheet.variables.color_secondary_dark;

        return (
            <TextBlockContainer
                className="text-block-container"
                ref={this.containerRef}
                emphasizedFontWeight={emphasizedFontWeight}
                colors={{
                    primary,
                    secondary,
                    emphasized: textStyles.emphasizedColor,
                    hyperlink: textStyles.hyperlinkColor,
                    // Legacy color
                    auto: textStyles.defaultColor ?? styles.color
                }}
                style={{
                    padding: isCalculatingLayout ? blockPadding : null,
                    opacity: isDragging ? 0.3 : null
                }}
            >
                {this.renderBlockDecoration(styles.textAlign)}
                {this.renderListDecoration(indentPadding, styles.textAlign)}
                <ContentEditable
                    id={this.uid}
                    key={textStyle ?? ""}
                    contentEditable
                    html={html}
                    innerRef={this.ref}
                    onClick={this.handleClick}
                    onCopy={this.handleCopy}
                    onFocus={this.handleFocus}
                    onBlur={() => { }}
                    onMouseDown={this.handleMouseDown}
                    className={textClasses.join(" ")}
                    style={styles}
                    lang="en"
                    spellCheck={!ignoreSpellcheck}
                    disableSelectionHandling={isCalculatingLayout || isDisabled}
                />
                {hasCanvasOverflow && showCanvasOverflow && <Backdrop />}
                {!isCalculatingLayout && showPlaceholder && <Placeholder
                    className={"text-block-placeholder"}
                    contentEditableStyles={styles}
                >
                    {placeholder.text}
                </Placeholder>}
                {!isCalculatingLayout && (DEBUG_TEXT_LAYOUT || window.debug.showLayoutDebugBox) && <>
                    {lines.map((line, lineIdx) => line.map((word, wordIdx) => (
                        <DebugBoundsBox
                            key={`${lineIdx}${wordIdx}`}
                            bounds={word.bounds}
                            thin
                            color="red"
                            name="wordBounds"
                        />
                    )))}
                    <DebugBoundsBox
                        bounds={containerBounds.zeroOffset()}
                        color="yellow"
                        name="containerBounds"
                    />
                    <DebugBoundsBox
                        bounds={bounds.offset(-containerBounds.left, -containerBounds.top)}
                        color="blue"
                        name="bounds"
                    />
                    <DebugBoundsBox
                        bounds={textBounds.offset(-containerBounds.left, -containerBounds.top)}
                        color="green"
                        name="textBounds"
                    />
                </>}
            </TextBlockContainer>
        );
    }
}

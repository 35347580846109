import React from "react";
import styled from "styled-components";

import { Icon } from "@material-ui/core";

import { DirectionType, NodeType } from "../../../../../common/constants";
import WidgetButton, { WidgetButtonPosition } from "../../../../Components/WidgetButton";
import { Button } from "../../../../Components/Button";
import { defaultDragPositionProps } from "../../../../editor/PresentationEditor/DragElementManager";

import { ControlBar } from "../ElementControlBars/Components/ControlBar";
import { CollectionItemElementSelection } from "./CollectionItemElementSelection";
import { ItemColorPicker } from "../EditorComponents/ColorPickers/ItemColorPicker";
import { EmphasizeToggle } from "../EditorComponents/EmphasizeToggle";
import { Popup, PopupContent } from "../../../../Components/Popup";
import { ImageOption, ImageOptionList } from "../../../../Components/ImageOptionList";
import { StaticImage } from "../../../../Components/StaticImage";
import { HorizontalPropertyList, PropertyPanelContainer, PropertySection } from "../../../../EditorComponents/PropertyPanel";
import { WithLabel } from "../../../../Components/WithLabel";
import { ToggleButton, ToggleButtons } from "../../../../Components/ToggleButtons";

const AddNodeButtonContainer = styled.div.attrs(({ direction }) => {
    switch (direction) {
        case DirectionType.TOP:
        case DirectionType.BOTTOM:
            return {
                style: {
                    left: "50%",
                    top: direction === DirectionType.TOP ? "0" : "100%",
                    transform: `translate(-50%, ${direction === DirectionType.TOP ? "-100%" : "0"})`
                }
            };
        case DirectionType.LEFT:
        case DirectionType.RIGHT:
            return {
                style: {
                    left: direction === DirectionType.LEFT ? "0" : "100%",
                    top: "50%",
                    transform: `translate(${direction === DirectionType.LEFT ? "-100%" : "0"}, -50%)`
                }
            };
    }
})`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 50%;
    pointer-events: ${({ disabled }) => disabled ? "none" : "auto"};
    cursor: pointer;

    .MuiIcon-root {
        color: ${({ disabled }) => disabled ? "#aaa" : "#50bbe6"};
        font-size: 14px;
    }
`;

const DeleteButton = styled(WidgetButton)`
    width: 16px;
    height: 16px;

    .MuiIcon-root {
        font-size: 14px;
    }
`;

export class OrgChartNodeSelection extends CollectionItemElementSelection {
    get canDrag() {
        const { element } = this.props;
        return !!element.parentNode;
    }

    get canDelete() {
        // Will use custom delete button
        return false;
    }

    get deleteButtonPosition() {
        return WidgetButtonPosition.INNER;
    }

    get showSelectionBorder() {
        const { element } = this.props;
        return !element.isInstanceOf("BoxOrgChartNode");
    }

    get dragPositionProps() {
        const { element } = this.props;

        const getNewProps = element => ({
            ...defaultDragPositionProps,
            customDropTargets: this.getCustomDropTargets(element),
            onDragEnd: ({ customDropTargetData }) => this.dropElement({ element, customDropTargetData }),
            getNewProps
        });

        return getNewProps(element);
    }

    dropElement({ element, customDropTargetData }) {
        if (!customDropTargetData) {
            return;
        }

        const { nodeId, direction } = customDropTargetData;

        const tree = element.tree;
        const originalParent = element.parentNode;
        const dropTarget = tree.getItemElementById(nodeId);

        if (dropTarget === element) {
            return;
        }

        if (dropTarget.isChildOf(element)) {
            element.childNodes.forEach(childNode => {
                childNode.model.parent = element.model.parent;
            });
        }

        if (direction === DirectionType.BOTTOM) {
            element.model.parent = nodeId;
            dropTarget.childNodes.forEach((childNode, index) => childNode.model.index = index);
        } else if (direction === DirectionType.LEFT || direction === DirectionType.RIGHT) {
            if (element.model.parent === dropTarget.model.parent) {
                let targetIndex = direction === DirectionType.LEFT ? dropTarget.nodeIndex : dropTarget.nodeIndex + 1;
                if (element.model.index <= dropTarget.model.index) {
                    targetIndex--;
                }
                const siblingModels = dropTarget.siblingNodes.map(node => node.model);
                siblingModels.remove(element.model);
                siblingModels.insert(element.model, targetIndex);
                siblingModels.forEach((model, index) => model.index = index);
            } else {
                const dropTargetSiblingModels = dropTarget.siblingNodes.map(node => node.model);
                dropTargetSiblingModels.insert(element.model, direction === DirectionType.LEFT ? dropTarget.nodeIndex : dropTarget.nodeIndex + 1);
                dropTargetSiblingModels.forEach((model, index) => {
                    model.index = index;
                    model.parent = dropTarget.model.parent;
                });
            }
        }

        if (originalParent) {
            originalParent.childNodes.forEach((childNode, index) => childNode.model.index = index);
        }
    }

    getCustomDropTargets(element) {
        const tree = element.tree;

        const isHorizontal = tree.model.layoutDirection === "horizontal";

        const customDropTargets = [];
        for (const node of tree.itemElements) {
            if (node.isAssistant) {
                continue;
            }

            const nodeBounds = node.canvasBounds;

            if (node.model.id !== element.model.id) {
                // Bottom
                customDropTargets.push({
                    bounds: isHorizontal ? nodeBounds.clone().deflate({ left: nodeBounds.width - 6 }).offset(13, 0) : nodeBounds.clone().deflate({ top: nodeBounds.height - 6 }).offset(0, 10),
                    id: `${node.model.id}|${DirectionType.BOTTOM}`,
                    data: {
                        nodeId: node.model.id,
                        direction: DirectionType.BOTTOM
                    },
                    dropOnEnd: true
                });
            }

            if (node.parentNode) {
                // Left
                customDropTargets.push({
                    bounds: isHorizontal ? nodeBounds.clone().deflate({ bottom: nodeBounds.height - 6 }).offset(0, -10) : nodeBounds.clone().deflate({ right: nodeBounds.width - 6 }).offset(-12, 0),
                    id: `${node.model.id}|${DirectionType.LEFT}`,
                    data: {
                        nodeId: node.model.id,
                        direction: DirectionType.LEFT
                    },
                    dropOnEnd: true
                });

                // Right
                customDropTargets.push({
                    bounds: isHorizontal ? nodeBounds.clone().deflate({ top: nodeBounds.height - 6 }).offset(0, 10) : nodeBounds.clone().deflate({ left: nodeBounds.width - 6 }).offset(13, 0),
                    id: `${node.model.id}|${DirectionType.RIGHT}`,
                    data: {
                        nodeId: node.model.id,
                        direction: DirectionType.RIGHT
                    },
                    dropOnEnd: true
                });
            }
        }

        return customDropTargets;
    }

    handleAddNode = async addDirection => {
        const { element, selectionLayerController } = this.props;

        const tree = element.tree;

        let direction;

        if (element.parentElement.model.layoutDirection === "horizontal") {
            if (addDirection === DirectionType.LEFT) {
                direction = DirectionType.TOP;
            } else if (addDirection === DirectionType.RIGHT) {
                direction = DirectionType.BOTTOM;
            } else if (addDirection === DirectionType.TOP) {
                direction = DirectionType.LEFT;
            } else {
                direction = DirectionType.RIGHT;
            }
        } else {
            direction = addDirection;
        }

        let newNodeModel;
        if (direction === DirectionType.BOTTOM) {
            newNodeModel = tree.addItem({
                parent: element.model.id,
                index: element.childNodes.length
            });
        } else if (direction === DirectionType.TOP) {
            newNodeModel = tree.addItem({
                parent: element.model.parent,
                index: element.model.index
            });
            element.model.parent = newNodeModel.id;
        } else {
            const siblingModels = element.siblingNodes.map(node => node.model);
            newNodeModel = tree.addItem({
                parent: element.model.parent
            });
            siblingModels.insert(newNodeModel, direction === DirectionType.LEFT ? element.nodeIndex : element.nodeIndex + 1);
            siblingModels.forEach((model, index) => model.index = index);
        }

        await tree.saveModel();

        await selectionLayerController.setSelectedElements([tree.getItemElementById(newNodeModel.id)]);
    }

    handleAddAssistant = async () => {
        const { element, selectionLayerController } = this.props;

        const tree = element.tree;

        const newNodeModel = tree.addItem({
            parent: element.model.id,
            index: element.childNodes.length,
            isAssistant: true
        });

        await tree.saveModel();

        await selectionLayerController.setSelectedElements([tree.getItemElementById(newNodeModel.id)]);
    }

    handleSetNodeStyle = nodeType => {
        const { element } = this.props;

        element.model.nodeType = nodeType;
        element.model.hilited = false;

        element.saveModel();
    }

    handleSetRowSize = size => {
        const { element } = this.props;

        const tree = element.tree;

        if (tree.model.smallRows === null) {
            tree.model.smallRows = [];
        }

        if (size === "small") {
            if (!tree.model.smallRows.contains(element.rowIndex)) {
                tree.model.smallRows.push(element.rowIndex);
            }
        } else {
            if (tree.model.smallRows.contains(element.rowIndex)) {
                tree.model.smallRows.remove(element.rowIndex);
            }
        }

        element.saveModel(true);
    }

    renderCustomChildren() {
        const { element } = this.props;

        const tree = element.tree;

        const isHorizontal = element.tree.model.layoutDirection === "horizontal";
        const isAssistant = element.isAssistant;
        const canAddNewNodes = element.tree.itemCount < element.tree.maxItemCount;
        const isTopNode = !element.parentNode;

        const addNodeDirections = Object.values(DirectionType)
            .filter(() => !element.isAssistant)
            .filter(direction => direction !== DirectionType.AUTO)
            .filter(direction => (
                (direction === DirectionType.BOTTOM && isTopNode && !isHorizontal) ||
                (isTopNode && direction === DirectionType.RIGHT && isHorizontal)
            ) || !isTopNode);

        return (<>
            {addNodeDirections.map(direction => (
                <AddNodeButtonContainer
                    key={direction}
                    direction={direction}
                    onClick={() => this.handleAddNode(direction)}
                    disabled={!canAddNewNodes}
                >
                    <Icon>add_circle</Icon>
                </AddNodeButtonContainer>
            ))}

            {!isTopNode && <DeleteButton
                onClick={this.handleDeleteElement}
                icon="close"
                hoverColor="orangered"
                outlined={false}
                left="calc(100% - 11px)"
                top="11px"
            />}

            <ControlBar left="50%" top={`calc(100% + ${isAssistant ? "25" : "35"}px)`}>
                <Popup icon="apps">
                    <PopupContent>
                        <PropertyPanelContainer>
                            <PropertySection>
                                <WithLabel label="Node Style">
                                    <ImageOptionList
                                        value={element.model.nodeType}
                                        onChange={this.handleSetNodeStyle}
                                        size={100}
                                    >
                                        <ImageOption value="person" label="Person">
                                            <StaticImage src="/images/ui/orgchart/node_person.svg" />
                                        </ImageOption>
                                        <ImageOption value="placeholder" label="Placeholder">
                                            <StaticImage src="/images/ui/orgchart/node_placeholder.svg" />
                                        </ImageOption>
                                        <ImageOption value="label" label="Label" disabled={tree.layout === "table"}>
                                            <StaticImage src="/images/ui/orgchart/node_label.svg" />
                                        </ImageOption>
                                    </ImageOptionList>
                                </WithLabel>
                            </PropertySection>
                            <PropertySection>
                                <HorizontalPropertyList>
                                    {tree.layout !== "table" &&
                                        <WithLabel label="Connector">
                                            <ToggleButtons
                                                disabled={isTopNode}
                                                exclusive value={element.connectorType}
                                                onChange={value => element.updateModel({ connectorType: value })}
                                            >
                                                <ToggleButton value="solid">Solid</ToggleButton>
                                                <ToggleButton value="dot">Dotted</ToggleButton>
                                            </ToggleButtons>
                                        </WithLabel>
                                    }
                                    <WithLabel label="Row Size">
                                        <ToggleButtons
                                            exclusive
                                            value={tree.isRowSmall(element.rowIndex) ? "small" : "normal"}
                                            onChange={this.handleSetRowSize}
                                            disabled={tree.layout === "table" || tree.options.forceSmallNodes !== true}
                                        >
                                            <ToggleButton value="normal">Normal</ToggleButton>
                                            <ToggleButton value="small">Small</ToggleButton>
                                        </ToggleButtons>
                                    </WithLabel>
                                </HorizontalPropertyList>
                            </PropertySection>
                        </PropertyPanelContainer>
                    </PopupContent>
                </Popup>
                {(element.nodeStyle === NodeType.BOX || element.nodeStyle === "photo1") &&
                    <EmphasizeToggle element={element} />
                }
                <ItemColorPicker element={element} showDecorationStyles />
                {isTopNode && !isHorizontal && <Button
                    blue
                    onClick={this.handleAddAssistant}
                    disabled={element.assistantNodes.length > 1 || !canAddNewNodes}
                >
                    Add Assistant
                </Button>}
            </ControlBar>
        </>);
    }
}

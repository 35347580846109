import React from "react";
import { v4 as uuid } from "uuid";

import { HorizontalAlignType } from "../../../../../../common/constants";
import * as geom from "../../../../../core/utilities/geom";
import { StatisticChangeInValueIconDefaultOverlay, StatisticPropertyPanel } from "../../../Editor/ElementPropertyPanels/GridContainer/Infographics/StatisticUI";
import { BaseElement } from "../../base/BaseElement";
import { TextElement } from "../../base/Text/TextElement";

export class Statistic extends BaseElement {
    static get schema() {
        return {
            showChangeInValue: true,
            showNote: false,
            value: {
                text: "$50,000"
            },
            changeLabel: {
                text: "100%"
            },
            positive: true
        };
    }

    getElementPropertyPanel() {
        return StatisticPropertyPanel;
    }

    get selectionPadding() {
        return 0;
    }

    get horizontalAlign() {
        return this.model.horizontalAlign ?? HorizontalAlignType.LEFT;
    }

    get showLabel() {
        return this.model.showLabel;
    }

    get showChangeInValue() {
        return this.model.showChangeInValue;
    }

    get showNote() {
        return this.model.showNote;
    }

    get minWidth() {
        return 50;
    }

    get minHeight() {
        return 20;
    }

    formatHtml(html) {
        html = html.replace("$", `<span class="text-superset">$</span>`);
        html = html.replace("%", `<span style="font-weight: 200">%</span>`);
        return html;
    }

    _build() {
        this.value = this.addElement("value", () => StatisticValue, {
            autoWidth: true,
            autoHeight: true,
            singleLine: true,
            // scaleTextToFit: true,
            syncFontSizeWithSiblings: false,
            // minTextScale: 0.001,
        });
        if (this.showChangeInValue) {
            this.changeInValue = this.addElement("changeInValue", () => StatisticChangeInValue);
            this.changeInValue.layer = 2;
        }

        if (this.showNote) {
            this.note = this.addElement("note", () => StatisticDescription, {
                autoWidth: true,
                autoHeight: true,
                scaleTextToFit: false,
                syncFontSizeWithSiblings: false,
            });
            this.note.layer = 2;
        }
    }

    _calcProps(props, options) {
        const { size } = props;

        let valueProps, changeInValueProps, noteProps;

        let availableHeight = size.height;
        let innerHeight = 0;
        let bottomWidth = 0;
        let bottomHeight = 0;

        let changeInValuePosition;

        if (this.showChangeInValue) {
            if (size.height < 100) {
                this.changeInValue.label.updateStyles({
                    fontSize: 12
                });
            }
            changeInValueProps = this.changeInValue.calcProps(size);
            bottomWidth = changeInValueProps.size.width;
            bottomHeight = changeInValueProps.size.height + 20;
        }

        if (this.showNote) {
            noteProps = this.note.calcProps(new geom.Size(size.width - (changeInValueProps ? changeInValueProps.size.width : 0), size.height / 2));
            bottomWidth += noteProps.size.width;
            bottomHeight = Math.max(bottomHeight, noteProps.size.height + 20);
        }

        availableHeight -= bottomHeight;

        const HEIGHT_SCALE = 0.8;
        valueProps = this.value.calcProps(new geom.Size(size.width, availableHeight * HEIGHT_SCALE));

        // calculate the scale of the value text
        // i am doing this manually instead of using scaleTextToFit because i want to scale the text more accurately than scaleTextToFit can
        // plus the range of scale is pretty large from one big cell to a small cell which has a performance hit with scaleTextToFit
        let valueScale = Math.min(size.width / valueProps.size.width, availableHeight * HEIGHT_SCALE / valueProps.size.height);
        valueProps = this.value.calcProps(new geom.Size(size.width, availableHeight * HEIGHT_SCALE), { forceTextScale: valueScale });

        innerHeight += valueProps.size.height;

        let valueY = (size.height - bottomHeight) / 2 - innerHeight / 2;

        let valueX;
        let bottomX;
        if (this.horizontalAlign === HorizontalAlignType.LEFT) {
            valueX = 0;
            bottomX = 0;
        } else if (this.horizontalAlign === HorizontalAlignType.CENTER) {
            valueX = size.width / 2 - (valueProps.size.width + (changeInValuePosition == "right" && changeInValueProps.size.width)) / 2;
            bottomX = size.width / 2 - bottomWidth / 2;
        } else {
            valueX = size.width - valueProps.size.width;
            bottomX = size.width - bottomWidth;
        }

        valueProps.bounds = new geom.Rect(valueX, valueY, valueProps.size);

        if (this.showChangeInValue) {
            changeInValueProps.bounds = new geom.Rect(bottomX, size.height - changeInValueProps.size.height, changeInValueProps.size);
        }

        if (this.showNote) {
            let x = this.showChangeInValue ? changeInValueProps.bounds.right + 10 : bottomX;
            let y = this.showChangeInValue ? (changeInValueProps.bounds.centerV - noteProps.size.height / 2) : size.height - noteProps.size.height;
            noteProps.bounds = new geom.Rect(x, y, noteProps.size);
        }

        // props.isFit = valueProps.isFit && valueProps.isTextFit;

        return { size };
    }

    get animateChildren() {
        return false;
    }
}

class StatisticValue extends TextElement {

}

class StatisticDescription extends TextElement {

}

class StatisticChangeInValue extends BaseElement {
    get iconId() {
        return this.model.icon ?? "arrow-up";
    }

    _build() {
        this.icon = this.addElement("icon", () => StatisticChangeInValueIcon, {
            canRollover: true
        });
        this.label = this.addElement("changeLabel", () => StatisticChangeInValueLabel, {
            autoWidth: true,
            autoHeight: true,
            placeholder: "Type value",
            syncFontSizeWithSiblings: true,
            scaleTextToFit: true,
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        const labelProps = this.label.calcProps(size);
        const iconProps = this.icon.calcProps(new geom.Size(labelProps.size.height, labelProps.size.height));
        iconProps.bounds = new geom.Rect(0, 0, iconProps.size);

        labelProps.bounds = new geom.Rect(iconProps.size.width, 0, labelProps.size);

        return { size: new geom.Size(iconProps.bounds.width + labelProps.bounds.width, labelProps.size.height) };
    }

    _applyColors() {
        if (this.parentElement.getBackgroundColor().isColor) {
            this.decoration.colorSet.fillColor = this.palette.getColor("white");
            this.colorSet.backgroundColor = this.decoration.colorSet.fillColor;

            if (this.model.positive) {
                this.icon.colorSet.iconColor = this.palette.getColor("positive");
                // this.label.colorSet.fontColor = this.palette.getColor("positive");
            } else {
                this.icon.colorSet.iconColor = this.palette.getColor("negative");
                // this.label.colorSet.fontColor = this.palette.getColor("negative");
            }
        } else {
            if (this.model.positive) {
                this.decoration.colorSet.fillColor = this.palette.getColor("positive");
                // if (!this.label.model.changeLabel.blocks[0]?.fontColor || this.label.model.changeLabel.blocks[0]?.fontColor === "primary") {
                //     this.label.colorSet.fontColor = this.palette.getColor("positive").darken(20);
                // }
            } else {
                this.decoration.colorSet.fillColor = this.palette.getColor("negative");
                // if (!this.label.model.changeLabel.blocks[0]?.fontColor || this.label.model.changeLabel.blocks[0]?.fontColor === "primary") {
                //     this.label.colorSet.fontColor = this.palette.getColor("negative").darken(20);
                // }
            }
            // force text and icon color to white (because bg is dark)
            this.colorSet.backgroundColor = this.palette.getColor("black");
            this.icon.colorSet.iconColor = this.palette.getColor("white");
            this.label.colorSet.fontColor = this.palette.getColor("white");
        }
    }
}

class StatisticChangeInValueLabel extends TextElement {

}

class StatisticChangeInValueIcon extends BaseElement {
    get iconId() {
        if (this.model.positive) {
            return "arrow_drop_up";
        } else {
            return "arrow_drop_down";
        }
    }

    get _doubleClickToSelect() {
        return false;
    }

    get showDefaultOverlay() {
        return true;
    }

    getElementRollover() {
        return null;
    }

    getElementDefaultOverlay() {
        return StatisticChangeInValueIconDefaultOverlay;
    }

    renderChildren() {
        // let color = this.model.positive ? this.palette.getColor("positive") : this.palette.getColor("negative");
        let color = this.colorSet.iconColor;
        let styles = {
            width: "100%",
            height: "100%",
            fontSize: 30,
            color: color.toHexString(),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        };
        return (
            <div className="material-symbols-outlined" key={uuid()} style={styles}>
                {this.iconId}
            </div>
        );
    }
}

import React, { Component } from "react";
import { v4 as uuid } from "uuid";

import * as geom from "js/core/utilities/geom";

export class AuthoringBlock extends Component {
    constructor() {
        super();

        this.state = {
            isDragging: false,
            hasFocus: false
        };

        this.ref = React.createRef();
        this.containerRef = React.createRef();

        this.uid = uuid();
    }

    get blockMargin() {
        return {
            top: 10,
            bottom: 10,
            left: 0,
            right: 0
        };
    }

    get bounds() {
        if (this.ref.current) {
            return geom.Rect.FromBoundingClientRect(this.ref.current.getBoundingClientRect()).inflate(this.blockMargin);
        }

        return null;
    }

    get model() {
        return this.props.model;
    }

    get indent() {
        return 0;
    }

    get index() {
        return this.props.index;
    }

    get scale() {
        return this.props.scale;
    }

    get type() {
        return this.model.type;
    }

    get element() {
        return this.props.element;
    }

    get selectedElements() {
        return this.props.selectionLayerController?.selectedElements ?? [];
    }
}

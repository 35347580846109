import React, { useEffect, useRef } from "react";

import { numeral } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import EditTextDialog from "js/react/components/Dialogs/EditTextDialog";
import { FormatType } from "common/constants";

export function ValueLabelSelection({ element, selectionLayerController }) {
    useEffect(() => {
        const selectionBounds = element.selectionBounds;
        const screenBounds = element.getScreenBounds().offset(0, -selectionBounds.height / 2);
        const targetPt = new geom.Point(screenBounds.centerH, screenBounds.centerV);

        const valueOriginal = element.parentElement.model.value;
        const valueScaled = element.parentElement.valueScaled;

        ShowDialog(EditTextDialog, {
            targetPt,
            target: element,
            minWidth: 150,
            value: valueScaled ?? valueOriginal,
            onClose: () => {
                selectionLayerController.setSelectedElements([element.parentElement]);
            },
            callback: value => {
                if (!numeral.validateEx(value)) {
                    return;
                }

                value = numeral(value).value();
                if (valueScaled !== undefined && valueOriginal !== valueScaled) {
                    value = value * (valueOriginal / valueScaled);
                }

                if (Math.abs(value) > 999999999999) {
                    value = value > 0 ? 999999999999 : -9999999999999;
                }

                element.parentElement.model.value = value;
                element.parentElement.canvas.updateCanvasModel(false).then(() => {
                    selectionLayerController.setSelectedElements([element.parentElement]);
                });
            },
        });
    }, []);

    return null;
}

// TODO: We may want to refactor this to use the same component as ValueLabelSelection
export function ValuePictorialChartLabelSelection({ element, selectionLayerController }) {
    useEffect(() => {
        const selectionBounds = element.selectionBounds;
        const screenBounds = element.getScreenBounds().offset(0, -selectionBounds.height / 2);
        const targetPt = new geom.Point(screenBounds.centerH, screenBounds.centerV);

        const valueOriginal = element.parentElement.model.value;
        const valueScaled = element.parentElement.valueScaled;

        const value = element.parentElement.format === FormatType.PERCENT
            ? (valueScaled ?? valueOriginal) : element.parentElement.model.labelValue;

        ShowDialog(EditTextDialog, {
            targetPt,
            target: element,
            minWidth: 150,
            value,
            onClose: () => {
                selectionLayerController.setSelectedElements([element.parentElement]);
            },
            callback: value => {
                if (!numeral.validateEx(value)) {
                    return;
                }

                if (element.parentElement.format === FormatType.PERCENT) {
                    value = numeral(value).value();
                    if (valueScaled !== undefined && valueOriginal !== valueScaled) {
                        value = value * (valueOriginal / valueScaled);
                    }

                    if (Math.abs(value) > 999999999999) {
                        value = value > 0 ? 999999999999 : -9999999999999;
                    }

                    element.parentElement.model.value = value;
                } else {
                    element.parentElement.model.labelValue = numeral(value).value();
                }

                element.parentElement.canvas.updateCanvasModel(false).then(() => {
                    selectionLayerController.setSelectedElements([element.parentElement]);
                });
            },
        });
    }, []);

    return null;
}

